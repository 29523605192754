.modal-container {
  height: 600px;
  &.ui.modal {
    width: 350px;
  }
  i {
    font-size: 1.2em;
  }
  .search {
    font-size: 1em;
  }
}

.sk-call-button {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: none;
  background-color: #00b5ad;
  cursor: pointer;
  outline: none;
  > i {
    color: white;
    font-size: 1.2em;
  }
  &:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
      0 17px 50px 0 rgba(0, 0, 0, 0.19);
  }
  &.force-left-margin {
    margin-left: 15px !important;
  }
}
