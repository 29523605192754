.main-grid-login {
  background-color: var(--bg-main);
  height: 100vh;

  .ui.column {
    max-width: 450px;
  }

  .ui.message {
    font-size: 12px;
  }

  .login-button {
    height: 36px;
    position: relative;
    padding: 0 !important;
  }

  .login-spinner {
    margin: auto;
  }

  .ui.grid.login-form {
    margin-top: -50px;
    height: 100%;
  }

  &.ui.grid {
    margin: 0;
  }

  &__forgot-password {
    display: flex;
    justify-content: flex-end;
    text-decoration: underline;
    margin: 0 0 1em;
  }

  .large.button {
    margin-bottom: 10px;
  }

  .google-btn {
    width: 184px;
    height: 42px;
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0,0,0,.25);
    cursor: pointer;
    margin: 10px auto;
    .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: #fff;
    }
    .google-icon {
      margin-top: 11px;
      width: 18px;
      height: 18px;
    }
    .btn-text {
      float: right;
      margin: 11px 11px 0 0;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-family: "Roboto";
    }
    &:hover {
      box-shadow: 0 0 6px #4285f4;
    }
    &:active {
      background: #1669F2;
    }
  }
}

@import url(https://fonts.googleapis.com/css?family=Roboto:500);