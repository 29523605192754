@import "../../../../../../../../../styles/colors";

.sk-activity-title {
  margin: 64px 0 0;
  text-align: center;
  color: #00b5ad;
}
.sk-agent-availability {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 200px;
  color: var(--syntax-main);

  &__report {
    padding: 16px;
  }

  &__calendar-filter .ui.vertical.menu {
    background-color: var(--bg-frame);
  }
}
