.overall-qa-report {
  display: flex;
  justify-content: space-evenly;
  &--column {
    flex-direction: column;
    margin-top: 48px;
  }

  &--none {
    margin-top: 0;
  }
  &__overall-reviews {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
    & > h2 {
      margin: 0 12px 0;
    }
  }

  &__success-ratio {
    margin-top: 48px;
    text-align: center;
    cursor: pointer;

    > h2 {
      color: var(--syntax-main);
    }
  }
}
