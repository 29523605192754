@import "../../styles/settings";

.confirm {
  z-index: $modal-z;
  @include fixed(top 50% left 50%);
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 6px;
  overflow: hidden;

  &__content {
    font-size: 1.2rem;
    background-color: $teal-color;
    color: white;
  }

  &__actions {
    @include flex(row, flex-end);

    > button {
      min-width: 80px;

      &.decline {
        color: gray;
        &:hover {
          text-decoration: underline;
        }
      }
      & + & {
        margin-left: 8px;
      }
    }
  }

  > * {
    padding: 16px 24px;
  }
}
