.sk-difference {
  padding-left: 32px;
}

.sk-activity-bar-container {
  margin: 32px 0 64px;
}

.sk-activity-tip {
  margin: 16px 0;
  display: block;
  font-style: italic;
  color: #212121;
  color: var(--syntax-main);
}

.sk-activity-bar {
  display: flex;
  border-radius: 4px;
  border: solid 2px #212121;
}

.sk-active-timespan {
  height: 50px;
  position: relative;
}

.sk-activity-container {
  max-width: 1400px;
  width: 100%;
  align-self: center;
}

.sk-availability-dates {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px;

  span {
    color: var(--syntax-main);
  }
}

.sk-availability-dates > div:first-child {
  margin-right: 12px;
}

.sk-pie-legend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px;
}

.sk-pie-legend .sk-difference {
  padding-left: 32px;
}

.sk-no-reports {
  color: var(--syntax-main);
  text-align: center;
  margin-top: 48px;
  font-size: 16px;
  font-weight: 600;
}
