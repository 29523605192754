.applicant-emails{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin:0 !important;
}

.email__subject{
     display:inline-block;
     width:450px;
     text-overflow:ellipsis;
     overflow:hidden;
     white-space:nowrap;
     transition: all .2s linear;
   
 }
 
 .email__subject:focus:after,.email__subject:hover:after{
     content:attr(data-text);
     overflow: visible;
     text-overflow: inherit;
     background-color: var(--bg-form-content);
     position: absolute;
     top:auto;
     width: auto;
     max-width: 30rem;
     border: 1px solid #eaebec;
     padding: 0 .5rem;
     box-shadow: 0 2px 4px 0 rgba(0,0,0,.28);
     white-space: normal;
     word-wrap: break-word;
     display:block;
     margin-top:-1.25rem;
   }
 