@import "../../../../styles/settings";

.notifications--dropdown {
  position: relative;

  .ui.dropdown {
    .menu:not(.scrolling) {
      right: 0;
      left: auto;
      max-height: 560px;
      overflow: auto;
      margin-top: 14px;
      min-width: 240px;
      border: 1px solid var(--syntax-main-inverse);

      @include below-phablet {
        position: fixed;
        margin-top: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 100%;
      }
    }
  }

  .ui.dropdown {
    .menu {
      &.visible:not(.scrolling) {
        @include below-phablet {
          border-radius: 0;
          display: flex !important;
          flex-direction: column;
        }
      }

      &.scrolling {
        @include below-phablet {
          max-height: none;
        }
      }
    }
  }

  > .ui.circular.label {
    @include absolute(bottom 12px left 12px);
  }

  &__subheader {
    display: flex;
    justify-content: space-between;
    background-color: var(--bg-frame);
    color: $theme-mode-blue;
    /** value copied from semantic */
    padding: 0.78571429em 0.57142812em 0.78571429em 1.14285714em;
    &__modal-trigger {
      cursor: pointer;
    }
  }

  &__title {
    flex-grow: 1;

    + div {
      > button {
        padding: 0;
      }
      .icon.delete {
        margin-left: 8px;

        @include above-phablet {
          display: none;
        }
      }
    }
  }

  .ui {
    &.dropdown {
      .scrolling.menu .item {
        border-bottom: 1px solid #b1ebeb;
      }
    }
  }

  &__message {
    &__ticket {
      font-size: 12px;
      margin-top: 4px;
      color: gray;
    }
  }

  &__empty-list {
    padding: 16px;
    text-align: center;
    font-size: 13px;
    color: gray;
    font-weight: 700;
  }
}

@mixin notification-item($color) {
  background-color: rgba($color, 0.1);

  &:hover {
    background-color: rgba($color, 0.15);
  }
}

.notification {
  &,
  .ui.modal &,
  .ui.dropdown & {
    display: flex;
    align-items: center;

    &--unread {
      font-weight: bold;

      &.red {
        @include notification-item($color-red);
      }

      &.green {
        @include notification-item($teal-color);
      }

      &:not(.green):not(.red) {
        @include notification-item($color-grey);
      }
    }

    &__text {
      @include flex(column);
    }
  }
}
