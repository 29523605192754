.request-document-modal {
    width: 50% !important;
  
    &__content {
      display: flex !important;
      flex-direction: column;
      gap: 16px;
  
      &__name {
        width: 100%;
      }
      &__other {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        &__input {
          width: 460px;
        }
      }
    }
  }
  