$semantic-border-color: rgba(34, 36, 38, 0.15);

$dropdownBackgroundColor: #e1f7f7;
$background-color-tinted: #616161;

$color-availabile: #54ab13;
$color-unavailable: #e3b207;
$color-offline: #6f6f6f;

$color-grey-f0: #f0f0f0;
$color-grey-e3: #e3e3e3;
$color-grey-ed: #edf6fb;
$color-grey-cc: #cccccc;
$color-grey-d5: #d5eaf6;
$color-grey-59: #595959;
$color-grey-57: #57575c;
$color-grey-34: #343437;
$color-grey: gray;

$color-blue-61: #61ccee;
$color-blue-08: #08d7ce;
$color-blue-0b: #0b2b51;
$color-blue: blue;

$color-black: black;

$color-white: white;

$color-red: red;

$teal-color: #00b5ad;

$color-green-e7: #e7fde5;
$color-green-01: #018605;
$color-green-00: #004c03;
$color-green: green;

$zendesk-green: #03363D;
$zendesk-middle-green: #17494d;
$zendesk-light-green: #DAEDED;

$light-mode-color-link: #a602b3;
$light-mode-sortable-th: #bdfab8;

$dark-mode-bg: #212122;
$dark-mode-bg-main: #1a1a1e;
$dark-mode-table-thead-tfood: #172224;
$dark-mode-table-tr-odd: #202020;
$dark-mode-general-report: #424242;
$dark-mode-drop-selected-item: #262626;
$dark-mode-border-theme: #4a4a4f;

$theme-border: #2077e1;
$theme-mode-blue: #6fb4c8;
$theme-cb-label: #ff7e7e;

$react-calendar-navigation: #6f6f76;
$react-calendar-today: #363641;
$react-calendar-range-hover: #325c8f;

$border-general-report-color: #afafaf;
