.ui.items > .item > .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .description > .dropdown {
    width: 80%;
  }
}
.document-content-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .manual-submit-toggle {
    margin-right: 50px;
    display: flex;
    > * {
      margin-right: 10px;
    }
  }
  .expiration-days-input {
    > .ui.input {
      margin-right: 20px;
      width: 100px;
    }
  }
}
.toggle::after {
  display: none;
}

.expiration-days-input,
.manual-submit-toggle,
.item,
.meta,
.text {
  color: var(--syntax-main);
}

.document-content-wrapper .item .content .ui.dropdown .menu .item .description {
  color: grey;
}
