@import "../../../styles/colors";

.ui.form .disabled.field {
  opacity: 1;
}

.ui.form .field label {
  color: var(--modal-form-color);
}

.credit-card-group {
  display: flex;
  justify-content: space-between;
  margin: 0 -8px;

  > div {
    flex-grow: 1;
    padding: 0 8px;
  }
}

.ui.form .ui.input > input:focus,
.ui.form textarea:focus {
  background-color: var(--bg-dropdown);
  color: var(--syntax-main);
}

.ui.inverted.dimmer {
  background-color: rgba(0, 0, 0, 0.5);
  .ui.loader.text {
    color: var(--syntax-main);
  }
}
