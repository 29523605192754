.embassy-modal-form {
  .ui.checkbox {
    padding: 8px;

    label {
      color: var(--modal-form-color) !important;
    }
  }

  .ui.button {
    margin-top: 16px;
  }
}
