.new-brochure-modal {
  width: 50% !important;

  &__content {
    display: flex !important;
    flex-direction: column;
    gap: 16px;

    &__name {
      width: 100%;
    }

    &__file {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center;
    }

    &__preview {
      display: flex;
      justify-content: center;
      height: 700px;
    }
  }
}
