@import "settings.scss";
@import "semantic-overrides.scss";
@import "../../node_modules/toastr/toastr.scss";

body ::-webkit-scrollbar {
  width: 5px;
}

:root {
  --top-menu-height: 4em;
}

:root {
  &.dark {
    --bg-frame: #{$dark-mode-bg};
    --bg-form-frame: #{$dark-mode-bg};
    --bg-form-content: #{$color-grey-59};
    --bg-main: #{$dark-mode-bg-main};
    --bg-table-thead: #{$dark-mode-table-thead-tfood};
    --bg-table-tfood: #{$dark-mode-table-thead-tfood};
    --bg-table-tr-odd: #{$dark-mode-table-tr-odd};
    --bg-table-tr-hover: #{$color-grey-59};
    --bg-segment-color: #{$dark-mode-bg};
    --bg-tabular-active-color: #{$color-white};
    --bg-table-colspan-color: #{inherit};
    --bg-table-segment-color: #{$color-blue-0b};
    --bg-table-segment-open-color: #{$color-green-01};
    --border-theme: #{$dark-mode-border-theme};
    --bg-general-report: #{$dark-mode-general-report};
    --bg-react-calendar: #{$dark-mode-bg};
    --bg-dropdown: #{$color-black};
    --bg-dropdown-hover: #{$dark-mode-bg-main};
    --bg-dropdown-selected-item: #{$dark-mode-drop-selected-item};
    --bg-empty-shift: #{$dark-mode-bg};
    --syntax-table-th: #{$color-blue-61};
    --syntax-table-tr: #{$color-grey-e3};
    --syntax-main: #{$color-white};
    --syntax-main-inverse: #{$color-black};
    --color-link: #{$color-blue-08};
    --team-availability-title: #{$theme-mode-blue};
    --sortable-th: #{$theme-mode-blue};
    --modal-form-color: #{$theme-mode-blue};
  }

  &.light {
    --bg-frame: #{$color-grey-34};
    --bg-form-frame: #{$color-grey-e3};
    --bg-form-content: #{$color-white};
    --bg-main: #{$color-white};
    --bg-table-thead: #{$color-green-e7};
    --bg-table-tfood: #{$color-white};
    --bg-table-tr-odd: #{$color-grey-ed};
    --bg-table-tr-hover: #{$color-grey-d5};
    --bg-segment-color: #{$color-blue-0b};
    --bg-tabular-active-color: #{$color-blue-0b};
    --bg-table-colspan-color: #{$color-blue-0b};
    --bg-table-segment-color: #{$color-blue-0b};
    --bg-table-segment-open-color: #{$color-green-01};
    --border-theme: #{$semantic-border-color};
    --bg-general-report: #{$color-grey-f0};
    --bg-react-calendar: #{$color-grey-57};
    --bg-dropdown: #{$color-white};
    --bg-dropdown-hover: #{$color-grey-cc};
    --bg-dropdown-selected-item: #{$color-grey-f0};
    --bg-empty-shift: #{white};
    --syntax-table-th: #{$color-black};
    --syntax-table-tr: #{$color-black};
    --syntax-main: #{$color-black};
    --syntax-main-inverse: #{$color-white};
    --color-link: #{$light-mode-color-link};
    --team-availability-title: #{$teal-color};
    --sortable-th: #{$light-mode-sortable-th};
    --modal-form-color: #{$color-blue-0b};
  }

  &.zendesk {
    --bg-frame: #{$color-grey-34};
    --bg-form-frame: #{$color-grey-e3};
    --bg-form-content: #{$color-white};
    --bg-main: #{$color-white};
    --bg-table-thead: #{$color-green-e7};
    --bg-table-tfood: #{$color-white};
    --bg-table-tr-odd: #{$zendesk-light-green};
    --bg-table-tr-hover: #{$color-grey-d5};
    --bg-segment-color: #{$zendesk-green};
    --bg-tabular-active-color: #{$color-blue-0b};
    --bg-table-colspan-color: #{$color-blue-0b};
    --bg-table-segment-color: #{$zendesk-middle-green};
    --bg-table-segment-open-color: #{$color-green-01};
    --border-theme: #{$semantic-border-color};
    --bg-general-report: #{$color-grey-f0};
    --bg-react-calendar: #{$color-grey-57};
    --bg-dropdown: #{$color-white};
    --bg-dropdown-hover: #{$color-grey-cc};
    --bg-dropdown-selected-item: #{$color-grey-f0};
    --bg-empty-shift: #{white};
    --syntax-table-th: #{$color-black};
    --syntax-table-tr: #{$color-black};
    --syntax-main: #{$color-black};
    --syntax-main-inverse: #{$color-white};
    --color-link: #{$light-mode-color-link};
    --team-availability-title: #{$teal-color};
    --sortable-th: #{$light-mode-sortable-th};
    --modal-form-color: #{$zendesk-green};
  }

    .ui.primary.button, .ui.blue.button {
      background-color: $zendesk-middle-green;
    }

    .sticky-bottom, .zendesk-top-menu {
      .ui.primary.button, .ui.blue.button {
        background-color: $zendesk-light-green;
        color: $color-black;
      }
    }
}

.grid {
  width: 100%;
  display: grid;
  grid: var(--top-menu-height) / 1fr;
  grid-template-areas:
    "menu"
    "maincontent";
}

.menu {
  grid-area: menu;
  z-index: 100;
}

.sk-small-content {
  padding: 10px;
  width: calc(100% - 4em);
}

.drop-left-padding {
  padding-left: 0 !important;
}

.label-on-corner {
  top: 0.8em !important;
  left: 78% !important;
}

.loader .ui.segment {
  height: 600px;
}

.ui.button.save-button {
  background-color: #00b5ad;
  color: white;
}
.ui.button.discard-button {
  background-color: rgb(202, 202, 202);
  color: #666666;
}

.tableTopSegment {
  padding: 12px !important;
  display: flex;
  flex-direction: column;
}

.ui.header.tableTitle {
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
}

.ui.tableTitle .filters-container .ui.checkbox label {
  color: $theme-cb-label;
  font-weight: bold;
}

html,
.pushable {
  background: var(--bg-main);
}

.ui.header.tableTitle > h3 {
  margin: 0;
}

.ui table {
  margin: 0 !important;
}

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;

  @include above-phablet {
    & > div {
      margin-right: 10px;
    }
  }

  @include below-phablet {
    display: grid;
    & > * {
      margin: 0 0 8px 0;
    }
  }
}

.adminTable {
  display: flex;
  justify-content: space-between;
}

.chart-container {
  margin-bottom: -32px;
}

.chart-legend {
  display: none;
}

.sk-textarea {
  width: 100%;
  min-height: 150px;
  text-align: left;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  resize: vertical;
}

#root {
  height: 100%;
}

.sk-modal-form-container {
  background-color: var(--bg-form-content);
  color: var(--syntax-main);
  display: flex;
  flex-direction: column;
  padding: 20px 30px 20px 30px;

  & > .sk-modal-form-item {
    margin: 12px;
    display: flex;
    & > .sk-modal-form-item-label {
      flex: 1;
    }
    & > .sk-modal-form-item-field {
      flex: 3;
      & > * {
        width: 100%;
      }
    }
  }
}

.sk-table-filters {
  display: flex;
  justify-content: flex-end;
  margin: 20px;
  & > * {
    margin-right: 10px;
  }

  &__brochures {
    display: flex;
    justify-content: flex-end;
    margin: 10px;
  }
}

.sk-table-filters-export-tool {
  display: flex;
  flex-direction: column;
  margin: 20px;
  gap: 10px;

  &__filters {
    display: flex;
    justify-content: flex-end;
    gap: 10px;
  }

  &__sections {
    display: flex;
    justify-content: flex-end;
  }
}

.sk-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sk-table-segment {
  padding: 10px;
  // background-color: ;
}

.animate-on-height {
  transition: height 150ms ease-in-out;
}

.ui.dropdown {
  button {
    + .menu {
      margin-top: 2px;
    }
  }
}

.react-datetime-picker {
  &__wrapper {
    border: 1px solid rgba(34, 36, 38, 0.15);
    color: var(--syntax-main);
    border-radius: 0.28571429rem;
    flex-grow: unset;
  }
  &__inputGroup,
  * {
    color: var(--syntax-main);
  }
}

.react-calendar {
  background-color: var(--bg-main);

  &__tile {
    &:enabled:hover {
      background-color: $color-grey;
    }
    &--now abbr {
      color: black;
    }
  }
}

.overlay {
  @include overlay;
  z-index: $modal-z - 1;
}

@media only screen and (max-width: 1275px) {
  .action-buttons {
    display: flex;
    flex-direction: column;
  }
  .action-buttons button {
    width: 30.25px;
  }
}

.ui.menu.top-menu.top-menu--simulation,
.ui.menu.top-menu {
  background: #1a1a1e;
  border-bottom: 1px solid $theme-border;

  .item.top-menu__no-border .ui.checkbox label,
  .top-menu__header-right__logged-as,
  a {
    color: $theme-mode-blue;
  }
}
.ui.slider.checkbox label:before {
  background-color: $color-grey;
}

.ui.selection.dropdown {
  @include above-phablet {
    margin-right: 10px;
  }
}

.ui.celled.table tr td:first-child,
.ui.celled.table tr th:first-child {
  font-weight: bold;
}

.ui.table thead th,
.ui.sortable.table thead th {
  font-size: 16px;
  white-space: nowrap;
}

.ui.pagination.menu {
  background-color: var(--bg-frame);
  border-bottom: 1px solid $theme-border;
  > a {
    font-weight: bold;
  }
  .item {
    color: $theme-mode-blue;

    &:hover {
      color: white;
    }
  }
  .active.item {
    color: white;
  }
}

.ui.attached.tabular .item {
  font-weight: bold;
  color: $theme-mode-blue;
}

.sk-chat-participants {
  h1 {
    color: $theme-mode-blue;
  }
}

.ui.vertical.menu {
  .active.item,
  .active.item:hover,
  .main-container__sidemenu__bottom .item:hover,
  .main-container__sidemenu__bottom .active.item,
  .item:hover .content .item:hover {
    color: $theme-mode-blue;
    font-weight: bold;
    .icon {
      color: $theme-mode-blue;
    }
    .text-icon {
      color: white;
    }
  }
}

.ui.accordion.menu .item,
.ui.accordion.menu .item .title,
.ui.accordion.menu .item .content .ui.vertical.menu .item {
  color: $theme-mode-blue;
  font-weight: bold;
}

.ui.vertical.menu {
  background: var(--border-theme);
}

.ui.segment {
  background: var(--bg-main);
}
.sk-session-item,
.ui.segments:not(.horizontal) > .segment:last-child {
  background: var(--bg-main);
  color: var(--syntax-main);
}

.ui.table,
.ui.sortable.table,
.ui.selectable.table {
  background: var(--bg-main);
  color: var(--syntax-main);
  border: 1px solid var(--border-theme);

  thead th,
  thead:not(.full-width) tr th:first-child {
    background-color: var(--bg-table-thead);
    color: var(--syntax-table-th);
  }

  thead tr .ui.checkbox label {
    color: var(--syntax-main);
  }

  tbody tr {
    &:hover,
    &.active {
      background-color: var(--bg-table-tr-hover) !important;
      td {
        color: var(--syntax-main);
      }
    }
  }
}

.ui.definition.table {
  thead:not(.full-width) {
    th:first-child {
      border: hidden;
      font-weight: bold;
      font-size: 20px;
    }
  }
  tbody tr td:first-child:not(.ignored) {
    color: var(--syntax-main);
  }
}

.ui.table tfoot tr:first-child > th:only-child,
.users-header {
  background: var(--bg-table-tfood);
}

.ui.striped.table tbody tr:nth-child(2n),
.ui.striped.table > tr:nth-child(2n) {
  background-color: var(--bg-table-tr-odd);
  color: var(--syntax-main);
}

.ui.striped.table {
  background-color: var(--bg-main);
  color: var(--syntax-table-tr);
  border: 1px solid var(--border-theme);

  tbody > tr > td[colspan="2"] > h2 {
    text-align: center;
    background: var(--bg-table-colspan-color);
    color: $color-white;
    border-radius: 4px;
    padding: 4px;
  }
}

a {
  text-decoration: none;
  font-weight: bold;

  &,
  &:hover {
    color: var(--color-link);
  }
}
.ui.inverted.segment {
  background-color: var(--bg-segment-color);
}

.ui.selection.dropdown,
.ui.button.dropdown {
  &,
  .menu,
  .item,
  .item.active {
    background-color: var(--bg-dropdown);
  }

  &:hover,
  & .item:hover {
    background-color: var(--bg-dropdown-hover);
  }

  & a.ui.label,
  .ui.button.dropdown a.ui.label {
    background-color: var(--bg-dropdown-selected-item);
  }
}

.ui.selection.dropdown {
  * {
    color: var(--syntax-main);
  }
  *.active {
    color: var($theme-mode-blue);
  }
}

.ui.button.dropdown {
  .menu {
    .item {
      background-color: var(--bg-dropdown);
    }
  }
}

.ui.input input,
.ui.input input[type="text"],
.ui.input input[type="email"],
.ui.input input[type="date"],
.ui.input input[type="number"],
.ui.input input[type="password"],
.ui.form textarea,
.ui.form .ui.message,
textarea.sk-textarea,
textarea.qa-questions-item {
  background-color: var(--bg-dropdown);
  color: var(--syntax-main);
}

::-webkit-scrollbar-track {
  background-color: $color-blue-0b !important;
}

::-webkit-scrollbar-thumb {
  background-color: $color-blue-61 !important;
}

* {
  scrollbar-color: $color-blue-61 $color-blue-0b;
  scrollbar-width: thin;
}

.ui.tabular.menu .active.item {
  background-color: var(--bg-tabular-active-color);
  color: var(--syntax-main-inverse);
}

.interviews-table {
  background-color: var(--bg-table-thead) !important;

  &__header {
    flex-direction: row;

    &__actions {
      display: flex;
      gap: 10px;

      .ui.button {
        width: 100px;
      }
    }
  }

  td {
    white-space: pre-line;
  }
}

.ui.bottom.attached.segment.active.tab {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  background-color: #1e2281;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize;
}

.avatar-container .avatar-text {
  font-size: 16pt;
}