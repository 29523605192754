@import "../../../../styles/settings.scss";
@import "../../../../styles/breakpoints";

.bot-status {
  min-width: 240px;
  width: max-content;

  &__item {
    width: 100%;

    &__title {
      display: flex;
      align-items: center;
      gap: 16px;
      padding: 12px 16px;
      background-color: var(--bg-frame);
      color: $theme-mode-blue;
      border-bottom: 1px solid;
      font-size: 16px;

      &__status {
        width: 10px;
        height: 10px;
        border-radius: 50%;
      }
    }

    &__content {
      width: 100%;
      padding: 12px 16px;
      font-weight: 700;
      font-size: 16px;
    }
  }
}

.bot-status__dropdown-item {
  padding: 0 !important;
}
