.sk-message-popup-modal {
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  // overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: contents; // to position dial pad container in modal

  .sk-message-popup-container {
    display: flex;
    background-color: white;
    width: 350px;
    height: 600px;
    margin: auto;
    align-content: center;

    .sk-message-popup-form {
      width: 100%;
      height: 600px;

      .sk-dial-pad-input-numbers {
        display: flex;
        flex-direction: column;
        flex: 0 0 90%;
        padding-right: 0.2em;
        padding-left: 0.3em;
        input {
          width: 62%;
        }
        .label {
          background-color: #00b5ad;
          color: white;
          border: 1px solid rgba(34, 36, 38, 0.15);
          width: 38%;
          // border-right: none;
        }
        .check {
          color: #00b5ad;
        }
        .delete {
          color: red;
        }
        .spinner {
          border-color: #00b5ad transparent transparent !important;
          border-top-color: #00b5ad;
          border-right-color: transparent;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }
      .sk-message-input-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 15%;
        padding-top: 5px;
      }
      .input {
        margin: 5px;
        color: yellow;
        input {
          width: auto;
        }
      }
      .sk-message-popup-textarea-container {
        display: flex;
        justify-content: center;
        height: 75%;
        padding-top: 5px;
        .field {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center;
        }
      }
      textarea {
        width: 95%;
        height: 100%;
        resize: none;
      }
      .sk-message-button-group {
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        width: 100%;
        justify-content: space-around;
        height: 10%;
        .sk-message-send-button {
          background-color: #00b5ad;
          color: white;
          width: 35%;
        }
        .sk-message-cancel-button {
          background-color: #db2828;
          color: white;
          width: 35%;
        }
      }
    }
  }
}
