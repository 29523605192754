@import "../../../../../../../styles/settings";

.qa-ticket-page-question-item {
  width: 45%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding-left: 10px;
  border: $border;

  margin: 16px 2.5%;

  & > .qa-ticket-page-question-title {
    border: $border;
    width: 200px;
    justify-self: flex-start;
    padding: 10px;
    margin: 0;
  }

  & > .qa-ticket-page-question-not-apply-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    align-self: flex-start;

    & > .qa-ticket-page-question-not-apply-label {
      border: $border;
      border-right: none;
      border-top: none;
      padding: 10px;
    }
  }
}
