.appn-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.appn-actions-container {
  width: 550px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 10px;
  color: var(--syntax-main);
}

.appn-text {
  text-align: center;
}

.appn-text h3 {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
}

.appn-text p {
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px;
}

.appn-cta {
  display: flex;
  justify-content: center;
  align-self: center;
}

/* Comment Box */
.appn-comment-box-title {
  width: 100%;
  font-size: 12pt;
}

.appn-comment-box-button {
  padding: 7px 12px;
  border-radius: 5px;
  border: none;
  font-size: 10pt;
  color: #ffffff;
  background-color: #2897bc;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.appn-comment-box-button:hover {
  background-color: #175469;
}

#publish-comment {
  width: 100%;
  height: auto;
  max-height: 300px;
  padding: 10px;
  border: none;
  font-size: 10pt;
  border: none;
  overflow: auto;
  outline: none;

  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;

  resize: none;
  field-sizing: content;
  background-color: var(--bg-form-content);
  color: var(--syntax-main);
}

.appn-comment-box {

  .appn-comment-box-actions {
    border: 2px solid #28bcbc;
    padding: 5px 5px;
    border-radius: 10px;
    background-color: var(--bg-form-content);
  }

  .appn-comment-box-options {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: end;
  }
}

.appn-timeline {
  margin: 10px 0px;
  position: relative;
  z-index: 1;
}

.appn-timeline-entry-content {
  width: 100%;
  position: relative;
}

.appn-timeline-entry {
  display: flex;
  align-items: start;
  gap: 10px;
  margin: 10px 0px;

  .appn-timeline-entry-avatar {
    img {
      width: 30px;
      height: 30px;
      border-radius: 1000px;
    }
  }

  .appn-timeline-entry-title {
    display: flex;
    flex-direction: row;
    justify-content: start;
    gap: 5px;

    .appn-timeline-entry-time {
      font-size: 8pt;
      color: #8f8f8f;
    }

    h3,
    p,
    span {
      font-size: 9pt;
      margin: 0px;
    }
  }

  .appn-timeline-comment-box {
    border: 1px solid #e0e0e0;
    padding: 10px;
    font-size: 9pt;
    border-radius: 10px;
  }
}

.appn-ticket-status-tag {
  background-color: #c5e8f3;
  color: #286275;
  padding: 5px 8px;
  border-radius: 5px;
}

.appn-timeline-comment-container {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.appn-line {
  width: 2px;
  height: 100%;
  border: 1px solid #e0e0e0;
  position: absolute;
  left: 20px;
  top: 0;
  z-index: -1;
}

.appn-timeline-entry-pin{
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0;
}

.appn-timeline-pinned-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.appn-timeline-pinned-title {
  display: flex;
  align-items: center;
}

.appn-timeline-pinned-button {
  background-color: transparent;
  border: none;
  cursor: pointer;
}