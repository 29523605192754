@import "../../../../../../../../../styles/settings.scss";

.team-availability {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 200px;
  color: var(--syntax-main);

  .ui.vertical.menu {
    background: var(--bg-frame);
  }

  &__reports {
    padding: 16px;

    > h2,
    h4 {
      text-align: center;
    }

    > h2 {
      color: var(--team-availability-title);
    }
  }
}
