@import "../../../../../../../../../styles/colors";

.specific-filters {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  height: 398px;
  overflow-y: scroll;
  &__filter {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px;

    &__category-title {
      margin-bottom: 18px;
      font-weight: bold;
      text-align: center;
    }

    &__title {
      margin-bottom: 18px;
      margin-right: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold;
    }

    &__checkbox {
      margin-bottom: 6px;
    }

    .ui.checkbox label {
      color: $theme-mode-blue;
    }
    .ui.checkbox.checked label {
      color: white;
    }
  }
  &__filter:last-child {
    margin-bottom: 0;
  }
}
