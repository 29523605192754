@import "../../../../../styles/settings";

.qa-ticket-page-ticket-info {
  border-radius: 10px;
  border: $border;
  padding: 5px;
  text-align: center;
  font-size: 1.3em;
}

.qa-ticket-page-section {
  border: $border;
  padding: 10px;
  border-radius: 10px;
  margin: 10px;

  > h3 {
    margin: 0 2.5%;
  }

  &__subheader {
    display: flex;
    justify-content: space-between;
    margin: 0 2.5%;

    .ui.dropdown.search.agent-ticket-dropdown {
      background: var(--bg-dropdown);
      background-color: rgba(0, 0, 0, 0.2);
      input[type="text"],
      .text,
      .message {
        color: var(--syntax-main);
      }
      .menu {
        background: var(--bg-dropdown);
        .item {
          color: var(--syntax-main);
          border-bottom: $color-grey;
          display: flex;
          justify-content: space-between;
          align-items: center;
          user-select: none;
          cursor: auto;
          background-color: rgba(0, 0, 0, 0.01);
          border-bottom: 1px solid grey;
        }
      }
    }
  }
}

.dropdown-adding-agent {
  float: right;
}

.qa-ticket-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 2.5% 0;
}

.qa-ticket-page-question-container {
  display: inline-block;
  width: 100%;
}
