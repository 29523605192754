@import "../../../../../../../styles/settings.scss";

.ticket-input {
  min-height: 400px;
}

.zendesk-configuration {
  .ui.fluid.vertical.menu .item,
  .ui.fluid.vertical.menu .active.item {
    background-color: var(--bg-main);
    border-bottom: 1px solid var(--border-theme);
  }
  .ui.fluid.vertical.menu .item {
    color: var(--syntax-main);
  }
  .ui.fluid.vertical.menu .active.item {
    color: $theme-mode-blue;
  }
}
