.ui.items > .item > .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;

  .description > .dropdown {
    width: 80%;
  }
}
.bot-autosubmit-content-wrapper,
.bot-statuschecker-content-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%;
  .bot-autosubmit-toggle {
    margin-right: 50px;
    display: flex;
    > * {
      margin-right: 10px;
    }
  }
  .bot-autosubmit-input {
    > .ui.input {
      margin-right: 20px;
      width: 100px;
    }
  }
}
.toggle::after {
  display: none;
}

.bot-item-container .item .content .meta,
.bot-item-container .item .content .bot-autosubmit-input,
.bot-item-container .item .content .bot-autosubmit-toggle,
.bot-item-container .item .content .bot-statuschecker-input,
.bot-item-container .item .content .bot-statuschecker-toggle,
.bot-item-container .item .content .ui.dropdown .text {
  color: var(--syntax-main);
}

.bot-item-container .item .content .ui.dropdown .menu .item .description {
  color: grey;
}

.bot-next-attempt {
  display: flex;
  flex-direction: column;
  color: white;
  margin: 8px 0;

  &__input {
    margin-top: 8px;
  }
}
