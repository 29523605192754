@import "../../../../../../../styles/colors";

.qa-report-page {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 200px;
  &__reports {
    * {
      color: var(--syntax-main);
    }

    display: flex;
    flex-direction: column;
    margin-right: 12px;

    &__below-overall {
      margin-top: 48px;

      &--grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;

        & > div {
          display: grid;
          grid-template-rows: 1fr 3fr;
        }
      }
      &__report {
        margin-bottom: 48px;

        .general-report,
        .cat-or-prod-qa-report__products__product,
        .cat-and-prod-qa-report__products__product,
        .cat-and-prod-qa-report__products__product__categories__category,
        .dispute-qa-report {
          background-color: var(--bg-general-report);
          border: 1px solid $border-general-report-color;
        }

        &__title {
          margin: 0 0 48px;
          display: flex;
          align-items: center;

          &--center {
            justify-content: center;
            text-align: center;

            & > span {
              margin-right: 0;
            }
          }
          &__text {
            margin-right: 12px;
            color: var(--syntax-main);
          }
        }
      }
    }
  }

  &__filter {
    width: 200px;
    background-color: var(--bg-frame);
    border-radius: 8px;

    * {
      color: $theme-mode-blue;
    }

    &__config {
      position: relative;
      z-index: 100;

      .ui.checkbox label {
        color: $theme-mode-blue;
      }
      .ui.checkbox.checked label {
        color: white;
      }
      .ui.selection.dropdown {
        margin-bottom: 4px;
      }

      & > div {
        margin-bottom: 14px;
      }
    }

    &__accordion {
      position: sticky;
      top: 70px;
      right: 0;
      .ui.vertical.menu {
        background: var(--bg-frame);
        border-radius: 0;
      }
    }
  }

  .apexcharts-legend-series {
    text-align: left;
  }
}
