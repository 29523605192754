.sk-dial-pad-product-selection {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center;
  & > * {
    margin-bottom: 15px;
  }
}

.sk-dial-pad-modal {
  // display: none; /* Hidden by default */
  display: contents; // to position dial pad container in modal
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  width: 100%; /* Full width */
  height: 100%; /* Full height */
  // overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */

  .sk-dial-pad-container {
    display: flex;
    align-content: center;
    flex-direction: column;
    background-color: white;
    margin: auto;
    height: 600px;

    .sk-dial-pad-buttons-container {
      height: 70%;

      .sk-dial-pad-buttons-row {
        display: flex;
        flex-direction: row;
        height: 25%;
        align-items: baseline;
      }
      .sk-dial-pad-button {
        background-color: white;
        text-align: center;
        flex: 1 0 33%;
        border: none;
        font-size: 2em;
        outline: none;
        height: 100%;
        &:hover {
          background-color: #00b5ad;
          color: white;
        }
        .sk-dial-pad-button-content {
          position: relative;
          > span {
            position: absolute;
            top: 100%;
            right: 50%;
            margin: 0;
            font-size: 0.5em;
            transform: translateX(50%);
            pointer-events: none;
          }
        }
      }
    }

    .sk-dial-pad-call-button {
      grid-column: 1 / span 3;
      border: none;
      background-color: #00b5ad;
      height: 12%;
      > i {
        color: white;
        font-size: 1.5em;
      }
    }

    .sk-dial-pad-call-warning-zendeskId {
      display: flex;
      flex-direction: column;
      background-color: #00b5ad;

      p {
        text-align: center;
        margin-top: 5px;
      }

      .button-group {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 5px;
      }
    }

    .sk-dial-pad-input-container {
      grid-column: 1 / span 3;
      text-align: center;
      display: flex;
      height: 18%;

      .sk-dial-pad-input-side {
        display: flex;
        flex: 0 0 7%;
      }

      .sk-dial-pad-input-numbers {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 0 0 90%;
        padding-left: 1em;
        input {
          width: 62%;
        }
        .label {
          background-color: #00b5ad;
          color: white;
          border: 1px solid rgba(34, 36, 38, 0.15);
          width: 38%;
        }
        .check {
          color: #00b5ad;
        }
        .delete {
          color: red;
        }
        .spinner {
          border-color: #00b5ad transparent transparent !important;
          border-top-color: #00b5ad;
          border-right-color: transparent;
          border-left-color: transparent;
          border-bottom-color: transparent;
        }
      }
    }
    .sk-dial-pad-input {
      // height: 50px;
      border: none;
      font-size: 1.5em;
      flex: 1 1 90%;
      // padding-left: 0.5em;
      text-align: center;
      outline: none;
    }
    .sk-dial-pad-delete-button {
      border: none;
      background-color: transparent;
      color: black;
      font-size: 1.5em;
      flex: 0 0 5%;
      align-self: baseline;
    }
  }
}

.sk-dial-pad-in-call-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: white;
  margin: auto;
  height: 600px;

  .img-container {
    display: flex;
    justify-content: center;
    margin-bottom: 0.2em;
    margin-top: 0.2em;
  }

  .sk-timmer-container {
    margin: 0.2em 0;

    .sk-timmer-label {
      color: #00b5ad;
    }
  }

  h5 {
    text-align: center;
    color: #00b5ad;
    font-size: 1.5em;
    margin: 0.2em 0;
  }
  h6 {
    color: #00b5ad;
    font-size: 1.5em;
    text-align: center;
    margin: 0.2em 0;
    flex: 0 0 5%;
  }
  .sk-dial-pad-buttons-container {
    min-height: 320px;
    // height: 320px;
    .sk-dial-pad-buttons-row {
      display: flex;
      flex-direction: row;
      height: 25%;
      align-items: baseline;
    }
    .sk-dial-pad-button {
      background-color: #00b5ad;
      text-align: center;
      flex: 1 0 33%;
      border: none;
      font-size: 2em;
      outline: none;
      height: 100%;
      &:hover {
        background-color: #00b5ad;
        color: white;
      }
      .sk-dial-pad-button-content {
        position: relative;
        > span {
          position: absolute;
          top: 100%;
          right: 50%;
          margin: 0;
          font-size: 0.5em;
          transform: translateX(50%);
          pointer-events: none;
        }
      }
    }
  }
  .sk-dial-pad-in-call-info-container {
    flex: 1 1 80%;
    display: flex;
    flex-direction: column;
    > img {
      align-self: flex-start;
    }
  }
  .sk-dial-pad-end-call-button {
    border: none;
    background-color: white;
    height: 60px;
    > i {
      color: red;
      font-size: 1.5em;
      transform: rotate(-135deg);
    }
  }
}

.sk-dial-pad-ringing-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: white;
  margin: auto;
  height: 600px;
  background-color: #00b5ad;
  .sk-dial-pad-ringing-info-container {
    flex: 1 1 80%;
    display: flex;
    flex-direction: column;
    > img {
      align-self: flex-start;
    }
  }
  .sk-dial-pad-menage-incoming-call-buttons {
    display: flex;
    justify-content: space-between;
    flex: 1 1 20%;
    background-color: white;
    .accept-call {
      color: white;
      // border: 2px solid white;
      margin-left: 3em;
      margin-top: 1.5em;
      width: 80px;
      height: 80px;
      padding: 0;
      > i {
        font-size: 1.5em;
      }
    }
    .reject-call {
      color: white;
      // border: 2px solid white;
      margin-right: 3em;
      margin-top: 1.5em;
      width: 80px;
      height: 80px;
      padding: 0;

      > i {
        font-size: 1.5em;
        transform: rotate(-135deg);
      }
    }
  }
}

.phone {
  position: relative;
  display: block;
  margin: 0;
  width: 80px;
  height: 80px;
  font-size: 25vmin;
  border-radius: 80px;
  box-shadow: 0 0 0 0em rgba(#3498db, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
  transform: translate3d(0, 0, 0) scale(1);
}
.phone.accept-call-icon {
  background-color: limegreen;
}
.phone.reject-call-icon {
  background-color: red;
  // border: 2px solid red;
}

.phone::before,
.phone::after {
  position: absolute;
  content: "";
}

.phone::before {
  top: 10px;
  left: 10px;
  width: 3em;
  height: 3em;
  background-color: rgba(#fff, 0.1);
  border-radius: 100%;
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(0);
}

.phone::after {
  top: 30px;
  left: 30px;
  width: 1em;
  height: 1em;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate3d(0, 0, 0);
}
.phone.is-animating {
  animation: phone-outer 3000ms infinite;

  &::before {
    animation: phone-inner 3000ms infinite;
  }

  &::after {
    animation: phone-icon 3000ms infinite;
  }
}

@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(#3498db, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
  }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow: 0 0 0 0em rgba(#3498db, 0.1),
      0em 0.05em 0.1em rgba(#000000, 0.5);
  }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0.5em rgba(#3498db, 0),
      0em 0.05em 0.1em rgba(#000000, 0.2);
  }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(#3498db, 0), 0em 0.05em 0.1em rgba(#000000, 0.2);
  }
}

@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0);
  }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9);
  }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0);
  }
}

@keyframes phone-icon {
  0% {
    transform: translate3d(0em, 0, 0);
  }
  2% {
    transform: translate3d(0.01em, 0, 0);
  }
  4% {
    transform: translate3d(-0.01em, 0, 0);
  }
  6% {
    transform: translate3d(0.01em, 0, 0);
  }
  8% {
    transform: translate3d(-0.01em, 0, 0);
  }
  10% {
    transform: translate3d(0.01em, 0, 0);
  }
  12% {
    transform: translate3d(-0.01em, 0, 0);
  }
  14% {
    transform: translate3d(0.01em, 0, 0);
  }
  16% {
    transform: translate3d(-0.01em, 0, 0);
  }
  18% {
    transform: translate3d(0.01em, 0, 0);
  }
  20% {
    transform: translate3d(-0.01em, 0, 0);
  }
  22% {
    transform: translate3d(0.01em, 0, 0);
  }
  24% {
    transform: translate3d(-0.01em, 0, 0);
  }
  26% {
    transform: translate3d(0.01em, 0, 0);
  }
  28% {
    transform: translate3d(-0.01em, 0, 0);
  }
  30% {
    transform: translate3d(0.01em, 0, 0);
  }
  32% {
    transform: translate3d(-0.01em, 0, 0);
  }
  34% {
    transform: translate3d(0.01em, 0, 0);
  }
  36% {
    transform: translate3d(-0.01em, 0, 0);
  }
  38% {
    transform: translate3d(0.01em, 0, 0);
  }
  40% {
    transform: translate3d(-0.01em, 0, 0);
  }
  42% {
    transform: translate3d(0.01em, 0, 0);
  }
  44% {
    transform: translate3d(-0.01em, 0, 0);
  }
  46% {
    transform: translate3d(0em, 0, 0);
  }
}

.sk-dial-pad-ringing-props {
  margin: auto;
  padding: 12px;
  border-radius: 20px;
  background-color: white;
  font-size: 1.2em;
  text-align: center;
}
