@import "variables.scss";
@import "breakpoints.scss";
@import "colors.scss";
@import "button.scss";
@import "tools/tools";

@mixin overlay {
  @include fixed(0);
  background-color: rgba(black, 0.5);
}
