@import "../../../../styles/settings.scss";

.main-container {
  display: flex;
  height: 100%;
  padding-top: $top-menu-height;

  &__sidebar {
    &.ui.left.sidebar {
      width: $side-menu-width;
    }
    &.menu {
      .item {
        padding: 16px 12px;
      }
    }
    &.ui.accordion .accordion {
      margin: 0;
    }
  }
  .ui.visible.left.sidebar ~ .pusher {
    overflow: auto;
    height: 100%;
  }

  &__sidemenu {
    overflow: hidden;
    z-index: 99;
    width: $side-menu-width;
    flex: 0 0 $side-menu-width;

    transition: width 0.3s, flex 0.3s;

    .ui[class*="left fixed"].menu {
      top: $top-menu-height;
    }

    .ui.vertical.menu {
      width: $side-menu-width;
      background: var(--bg-frame);

      .item .menu .item,
      .title,
      .item {
        font-weight: bold;
      }
    }
    &.menu {
      .item {
        padding: 16px 12px;
        i {
          margin-right: 0;
          transition: margin-right 300ms;
        }
      }
    }

    &--shrunk {
      width: $side-menu-width-shrunk !important;
      flex: 0 0 $side-menu-width-shrunk;

      .ui.vertical.menu {
        width: $side-menu-width-shrunk;
      }
      .item {
        i {
          margin-right: 24px;
        }
      }
    }
  }

  &__content {
    flex: 1 1 100%;
    padding: 2em 1em;
    background-color: var(--bg-main);

    &::-webkit-scrollbar {
      width: 0.8em;
    }

    .attached.tabular.menu {
      .item:hover {
        color: $theme-mode-blue;
      }
    }

    &--no-padding {
      padding: 0;
    }
  }
}
