.dx-visibility-change-handler {
  padding-top: 32px;
}

.dxc-legend {
  transform: translateY(calc(50% - 34px));
}

.dcx-series-group,
.dxc-labels-group {
  transform: translateX(-4px);
}
