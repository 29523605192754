@import "../../../../../../../styles/colors";

.user-profile-container {
  display: flex;
  flex-direction: column;

  .user-profile-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 4em;
    background-color: #00b5ad;
    color: white;
    font-size: 16px;

    .user-profile-header-email {
      margin-right: 0.5em;
    }

    .profileImage {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-right: 1em;
      background-color: #0ce6db;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .user-profile-content {
    // background-color: gray;
    h2 {
      color: rgb(8, 170, 170);
      display: flex;
      justify-content: center;
    }

    h4 {
      color: var(--syntax-main);
      margin-left: 0.5em;
    }

    .user-profile-content-permissions {
      border: 1px solid $color-grey;
      background-color: var(--bg-main);
      display: flex;
      justify-content: space-evenly;
      flex-direction: column;
      width: 40%;
      padding: 1.5em 1em 0em 1em;

      .permission {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        color: var(--syntax-main);
      }

      p {
        margin-bottom: 2em;
        margin-top: 0;
      }
    }
  }

  .user-profile-apply-changes-button {
    display: flex;
    justify-content: flex-end;
  }
}
