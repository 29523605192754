.send-email-modal {
  width: 70% !important;

  &__content {
    &__container {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 32px;

      &__field {
        display: flex;
        flex-direction: column;
        gap: 8px;

        &__label {
          width: max-content;
          margin-right: 10px !important;
        }

        &__input {
          flex: 0 0 50%;
        }

        &__checkboxes {
          display: flex;
          gap: 32px;
          flex-wrap: wrap;
        }

        &__header {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}

.document-segment-group {
  padding: 16px;
  display: flex;
  flex-direction: column !important;
  
  > div:first-child {
    display: flex;
    flex-direction: row !important;
    gap: 8px;
    align-items: center;
  }

  > .checkbox {
    flex: 0 0 50px;
  }

  &__info {
    flex: 1 1 100%;
    display: flex;
    justify-content: space-between;

    > label {
      cursor: pointer;
    }

    &__preview {
      display: flex;
      align-items: center;
      gap: 8px;

      > span {
        color: #08d7ce;
      }

      .ui.toggle.checkbox label:before, .ui.checkbox input:focus~label:before {
        background-color: rgba(128,128,128,1);
      }
    }
  }
}
