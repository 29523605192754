@import "../../../../../../../../../styles/settings.scss";

.specific-filters .search-and-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
  text-align: center;
  &__search {
    & input {
      width: 100%;
    }

    .input {
      .prompt {
        background-color: white;
        color: black;
      }
    }

    .result .result-selected {
      color: $theme-mode-blue;
      font-weight: bold;
    }
  }

  &__list {
    max-height: 328px;
    overflow-y: auto;

    background-color: $background-color-tinted;

    &--all-selected {
      background-color: unset;

      .item .content .header {
        color: $theme-mode-blue;
      }
    }

    &__item {
      margin: 3px 0;
      display: flex;
      flex-direction: row-reverse;
      text-align: justify;

      .content .header {
        color: white;
      }

      &__icon {
        cursor: pointer;
        color: white;
        &:before:hover {
          color: $theme-mode-blue;
        }
      }
    }
  }
}
