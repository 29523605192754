.brochures_archive_modal {
  max-width: 80%;
}

.brochure-actions {
  display: flex;
  align-items: center;

  .ui.selection.dropdown {
    padding: 4px;
    min-height: initial;
    min-width: 200px;

    .text {
      font-size: 16px;
    }

    .dropdown.icon {
      padding: 4px;
    }
  }
}