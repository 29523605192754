@import "../../../../styles/settings.scss";

.zendesk-top-menu {
  height: $top-menu-height;
  justify-content: center;
  background-color: var(--bg-segment-color) !important;

  .item {
    padding: 13px 13px !important;
  }
  
  > .item {
    padding: 13px 40px !important;
  }
  
  > .ui.button.blue {
    border-color: gray;
  }

  .dropdown .ui.dropdown.button {
    background-color: $color-green-01;
    &:hover {
      background-color: $color-green-00;
    }
  }

  &__no-border::before {
    display: none;
  }

  &__header-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    width: 50%;

    .selection.dropdown {
      min-width: 10em;
    }

    &__logged-as {
      font-weight: bold !important;
      color: $theme-mode-blue !important;

      &__user {
        padding-left: 5px;
        color: $teal-color;
      }
    }

    &__item {
      &::before {
        display: none;
      }
    }

    &__log-out {
      &:hover {
        .text-icon {
          color: white;
        }
      }
      .icon {
        color: $theme-mode-blue;
      }
      .text-icon {
        font-weight: bold;
        line-height: initial;
        color: $theme-mode-blue;
      }
    }
  }
}
