@import "../../../../../../styles/breakpoints";
@import "../../../../../../styles/settings.scss";

.ui.modal {
  border-radius: 8px;
  width: fit-content;

  @include below-phablet {
    & {
      bottom: 0;
      border-radius: 5px 5px 0 0;
    }
  }

  & > .header {
    background-color: var(--bg-form-frame);
    color: var(--modal-form-color);
  }

  & > .content,
  & > .content .ui.form .field label {
    background-color: var(--bg-form-content);
    color: var(--syntax-main);
  }

  & > .actions {
    background-color: var(--bg-form-frame);
  }
}

.notifications {
  .ui.modal > & {
    padding: 0 !important;

    max-height: 50vh;
    overflow: auto;
  }
}

.notification {
  .ui.modal & {
    cursor: pointer;
    padding: 0.98571429em 1.41285714em;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}
