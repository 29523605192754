.icon-container {
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  padding: 6px;
}

.icon-container:hover {
  background-color: red;
}

.icon-container i {
  margin: 0;
  color: #00b5ad;
}
.icon-container:hover i {
  margin: 0;
  color: white;
}
