.tran-or-prod-report {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  &__products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
    &__product {
      width: 100%;
      padding: 24px 12px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer;

      &__no-data {
        align-self: center;
        display: flex;
        flex-direction: column;
        height: 75%;
        justify-content: center;
      }
    }
  }
}
