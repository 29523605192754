@import "../../../../../../../styles/settings.scss";

$defaultPadding: 10px;
$semanticBorderColor: var(--syntax-main);
$semanticBorderRadius: 0.28571429rem;

$semanticBorder: 1px solid $semanticBorderColor;

.ui.divided.items.alarms-tab {
  .ui.horizontal.list {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  .email-container {
    display: flex;
    justify-content: space-evenly;
    margin-top: 10px;
    padding-top: $defaultPadding;
    border-top: $semanticBorder;
  }

  .email-container-email {
    display: flex;
    flex-direction: column;
    border: $semanticBorder;
    padding: $defaultPadding;
    border-radius: $semanticBorderRadius;
    width: 70%;

    & > div.input {
      margin-bottom: 15px;
    }

    & > textarea {
      border-radius: $semanticBorderRadius;
      border-color: $semanticBorderColor;
      padding: $defaultPadding;
    }
  }

  .email-container-legenda {
    border: $semanticBorder;
    padding: $defaultPadding;
    border-radius: $semanticBorderRadius;
    color: var(--syntax-main);
    & > div {
      height: 40px;
      border-radius: 10px;
      padding: $defaultPadding;
      &:hover {
        background-color: grey;
        color: white;
        cursor: pointer;
      }
    }
  }

  .item .content {
    .header:not(.ui) {
      color: $teal-color;
    }
    .ui.horizontal.list .item .meta,
    .ui.horizontal.list .item label,
    .ui.horizontal.list .item .description .item {
      color: var(--syntax-main);
    }
  }

  > .item:not(:first-child) {
    border-top: $semanticBorder;
  }
}
