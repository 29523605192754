@import "../../../styles/colors";

.ui.small.modal.popup-basic-class {
  > div {
    background-color: var(--bg-main);
  }
}

.ui.small.modal.delete-popup {
  > .header {
    color: #af0000;
  }
}

.ui.primary.button.confirm-popup {
  background-color: var(--modal-form-color);
  margin: 0;
}

.ui.primary.button.delete-popup {
  background-color: #af0000;
  margin: 0;
}
