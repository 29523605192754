.applicants-deletion{
    &__table-header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > .header {
            width: 50% !important;
        }

        .users-filters {
            gap: 8px;

            .field {
                input {
                    padding-left: 1em !important;
                }
            }
        }
    }

}