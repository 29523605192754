@import "../../../../../../../styles/settings";

.ui.fixed.table td,
.ui.fixed.table th {
  overflow: unset;
}

td > div {
  width: 100%;

  &.field > .ui.input {
    width: 100%;
  }
}

.sticky {
  position: sticky;
  top: 4rem;
  z-index: 100;
}

.sticky-steps {
  position: sticky;
  top: 8rem;
  z-index: 100;
}

.applicant-view-container {
  .ui.header {
    margin: 0;
  }

  & > .custom-header {
    display: flex;
    justify-content: space-between;
    padding: 12px;

    & > div {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      & > * {
        margin: 0 !important;
      }
    }
  }

  & > .application-commons-container {
    display: flex;
    justify-content: space-between;
    padding: 12px;

    & > div {
      display: flex;
      flex-wrap: wrap;
      gap: 4px;

      & > button {
        margin: 0 !important;
      }
    }
  }

  & > .application-icons-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding: 12px;
  }

  & > .application-icons-container > button {
    margin-bottom: 4px;
  }

  .document-menu-buttons {
    display: flex;
    justify-content: space-between;
  }

  .edit-errors-containter{
    display: flex;
    margin-left:12px;
  }
}


.table-header-links {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}


.table-link-button {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12px;
  color: aqua;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.sticky-bottom {
  position: sticky;
  bottom: 0;
  z-index: 900;
}

.buttons-container {
  display: flex;
  justify-content: flex-end;
}

.zendesk-icon {
  width: 36px;
  height: 36px;
}

.error-list {
  display: flex;
  flex-direction: column;
  padding: 8px;
  color: red;

  > span {
    color: red;
  }
}

.change-type-modal {
  .ui.button {
    margin-top: 16px;
  }
}

.group-pack-status {
  margin-left: 8px;
}

.appn-timeline-pinned-notes {
  width: 98%;
  color: var(--syntax-main);
  border: 1px solid #dadada;
  border-radius: 10px;
  margin: 20px 1%;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}