@import "colors.scss";

$border-radius: 20px;
$top-menu-height: 4em;
$side-menu-width: 14em;
$side-menu-width-shrunk: 3.6em;

$semanticBorderRadius: 0.28571429rem;

$border: solid $color-grey 1px;

$modal-z: 120;
