@import "../../../styles/breakpoints";
@import "../../../styles/colors";

.modal-table {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  z-index: 1000;

  &__body {
    background-color: var(--bg-main);
    color: var(--modal-form-color);
    padding: 16px;
    position: absolute;
    max-height: 95%;
    overflow: auto;

    @include below-phablet {
      & {
        overflow: auto;
        border-radius: 5px 5px 0 0;
        bottom: 0;
        width: 100%;
      }
    }

    @include above-phablet {
      & {
        border-radius: 5px;
        width: 1200px;
        max-width: 90%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }

    .overflow {
      float: left;
      width: 350px;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    
    .overflow:hover {
      overflow: visible;
    }
    
    .overflow:hover span {
      position: relative;
      width: 350px;
      background-color: white;
      right: 0;
      box-shadow: 0 0 4px 0 black;
      border-radius: 1px;
      text-wrap: wrap;
    }
  }
}

.ui.button.modal-table__button {
  color: white;
  background-color: var(--modal-form-color);
}
