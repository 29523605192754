@import "../../../../../../../../../styles/settings.scss";

.billing-report-charts {
  display: flex;
  flex-direction: column;

  &__config {
    width: 200px;
  }

  &__title {
    display: flex;
    align-items: center;
    margin: 48px 0;

    &__text {
      margin: 0 12px 0 0;
    }
  }

  .tran-or-prod-report__products__product {
    background-color: var(--bg-general-report);
    border: 1px solid $border-general-report-color;
  }
}
