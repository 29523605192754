$border-radius: 20px;

.sk-incoming-call-glider {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: fit-content;
  padding: 0 0 20px 20px;
  background-color: #c0c0c0;
  position: fixed;
  z-index: 5;
  border-radius: $border-radius;
  right: 0;
  top: 0;
  & > .sk-incoming-call-row {
    display: flex;
    &:nth-child(1) {
      justify-content: flex-end;
    }
    &:nth-child(2) {
      justify-content: space-evenly;
    }
    & > .button {
      margin: 0;
      height: 40px;
    }

    & > .call-button {
      border-radius: 50% !important;
      height: 50px !important;
      width: 50px !important;
    }
  }
}

.sk-incoming-call-minimized-button {
  width: 50px;
  height: 50px;
  background-color: #c0c0c0 !important;
  position: fixed !important;
  right: 0;
  top: 0 !important;
  z-index: 5;
  border-radius: $border-radius;
}

.sk-call-participant {
  align-self: center;
  font-size: 1.3em;
  margin: auto;
}

.sk-top-margin {
  margin-top: 20px;
}
