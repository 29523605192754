.cat-and-prod-qa-report {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  &__products {
    display: flex;
    flex-direction: column;
    &__product {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 24px 12px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 24px;
      &__categories {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 12px;
        margin-top: 6px;
        &--empty {
          grid-template-columns: 1fr;
        }
      }
    }
  }
}
