@import "../../../../styles/settings.scss";
@import "../../../../styles/breakpoints";

.dropdown {
  &.online-agents {
    .menu {
      max-height: 50vh;
      width: 320px;
      overflow: auto;
      border: 1px solid var(--syntax-main-inverse);
    }
  }

  &__subheader {
    background-color: var(--bg-frame);
    color: $theme-mode-blue;
    border-bottom: solid 1px;
    /** value copied from semantic */
    padding: 0.78571429em 1.14285714em;
    position: sticky;
    top: 0;
    z-index: 15;
  }

  &__item {
    &__agent {
      display: flex;
      flex-direction: column;

      &__name {
        color: var(--syntax-main);
      }

      &__email {
        font-size: 12px;
        margin-top: 4px;
        color: gray;
      }
    }

    &__status {
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background-color: #616161;
      border: solid 2px #616161;
      margin-left: 16px;

      &--available {
        background-color: $teal-color;
      }

      &--unavailable {
        background-color: #ffeb3b;
      }
    }
  }

  &__empty-list {
    padding: 16px;
    text-align: center;
    font-size: 13px;
    color: gray;
    font-weight: 700;
  }

  .ui {
    &.dropdown {
      &.button {
        background-color: $teal-color;

        .icon {
          padding-left: 14px;
          color: white;
        }
      }

      > .text {
        color: white;
      }

      .menu {
        position: absolute;
        right: 0;
        left: auto;
        top: 40px;

        .item.dropdown__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
        }
      }
    }
  }
}
