.review-certification{
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    gap: 128px;
    padding-top: 32px;

    &__menu {
    display: flex;
    flex-direction: column;
    gap: 32px;
    margin: 0 0 8px 8px;

    > .header {
        padding-left: 16px;
    }
    }

    &__content {
        display: flex;
        flex-direction: column;
        flex: 1;
        padding-right: 128px;
    }

    &__add-new-modal {
        .modal-form__body{
            min-height: 360px;
        }
    }
}