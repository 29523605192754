.cat-or-prod-qa-report {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;

  &__products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 24px;
  }
}
