@import url(https://fonts.googleapis.com/css?family=Roboto:500);
.sk-spinner {
  border: 3px solid #f3f3f3; /* Light grey */
  border-top: 3px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 0.8s linear infinite;
          animation: spin 0.8s linear infinite;
  margin: 0 auto;
}

@-webkit-keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.portrait-container {
  position: relative;
  display: inline-block;
  cursor: pointer; }

.portrait-container .ui.dimmer {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 5px; }

.ui.toggle.checkbox.custom-toggle input:checked ~ label:before {
  background-color: #00b5ad !important; }

.custom-select {
  position: relative;
  display: flex;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  flex-wrap: wrap;
  cursor: pointer;
  min-height: 2.869243em;
  align-items: center;
  background-color: var(--bg-dropdown);
  color: var(--syntax-main); }

.select-values {
  margin: 4px;
  padding: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  background-color: var(--bg-dropdown-selected-item);
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  cursor: auto; }
  .select-values .ui.button.delete-value-button {
    padding: 4px;
    margin-left: 4px;
    background-color: var(--bg-dropdown-selected-item);
    width: 20px; }
    .select-values .ui.button.delete-value-button:hover {
      background-color: var(--bg-dropdown-hover); }
  .select-values .delete-value-icon.x.icon:before {
    vertical-align: middle;
    color: var(--modal-form-color); }

.custom-select-options {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: none;
  z-index: 1001;
  position: relative;
  left: 0;
  right: 0;
  background: var(--bg-dropdown);
  max-height: 200px; }
  .custom-select-options > div {
    background-color: var(--bg-dropdown);
    padding: 8px;
    color: var(--syntax-main);
    -webkit-user-select: none;
       -moz-user-select: none;
        -ms-user-select: none;
            user-select: none;
    cursor: pointer; }
    .custom-select-options > div:not(:last-child) {
      border-bottom: 1px solid rgba(34, 36, 38, 0.15); }
    .custom-select-options > div:hover {
      color: white;
      background-color: var(--modal-form-color); }

.open-options {
  display: none; }

.template-modal-form .ui.button {
  margin-top: 16px; }

.template-modal-form .tagsAndPropsRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 10px;
  gap: 10px; }

.send-email-modal {
  width: 70% !important; }
  .send-email-modal__content__container {
    height: 100%;
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px; }
    .send-email-modal__content__container__field {
      display: flex;
      flex-direction: column;
      grid-gap: 8px;
      gap: 8px; }
      .send-email-modal__content__container__field__label {
        width: -webkit-max-content;
        width: -moz-max-content;
        width: max-content;
        margin-right: 10px !important; }
      .send-email-modal__content__container__field__input {
        flex: 0 0 50%; }
      .send-email-modal__content__container__field__checkboxes {
        display: flex;
        grid-gap: 32px;
        gap: 32px;
        flex-wrap: wrap; }
      .send-email-modal__content__container__field__header {
        display: flex;
        align-items: center;
        justify-content: space-between; }

.document-segment-group {
  padding: 16px;
  display: flex;
  flex-direction: column !important; }
  .document-segment-group > div:first-child {
    display: flex;
    flex-direction: row !important;
    grid-gap: 8px;
    gap: 8px;
    align-items: center; }
  .document-segment-group > .checkbox {
    flex: 0 0 50px; }
  .document-segment-group__info {
    flex: 1 1 100%;
    display: flex;
    justify-content: space-between; }
    .document-segment-group__info > label {
      cursor: pointer; }
    .document-segment-group__info__preview {
      display: flex;
      align-items: center;
      grid-gap: 8px;
      gap: 8px; }
      .document-segment-group__info__preview > span {
        color: #08d7ce; }
      .document-segment-group__info__preview .ui.toggle.checkbox label:before, .document-segment-group__info__preview .ui.checkbox input:focus ~ label:before {
        background-color: gray; }

.request-document-modal {
  width: 50% !important; }
  .request-document-modal__content {
    display: flex !important;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px; }
    .request-document-modal__content__name {
      width: 100%; }
    .request-document-modal__content__other {
      display: flex;
      flex-direction: row;
      justify-content: space-between; }
      .request-document-modal__content__other__input {
        width: 460px; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.dropdown.online-agents .menu {
  max-height: 50vh;
  width: 320px;
  overflow: auto;
  border: 1px solid var(--syntax-main-inverse); }

.dropdown__subheader {
  background-color: var(--bg-frame);
  color: #6fb4c8;
  border-bottom: solid 1px;
  /** value copied from semantic */
  padding: 0.78571429em 1.14285714em;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 15; }

.dropdown__item__agent {
  display: flex;
  flex-direction: column; }
  .dropdown__item__agent__name {
    color: var(--syntax-main); }
  .dropdown__item__agent__email {
    font-size: 12px;
    margin-top: 4px;
    color: gray; }

.dropdown__item__status {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #616161;
  border: solid 2px #616161;
  margin-left: 16px; }
  .dropdown__item__status--available {
    background-color: #00b5ad; }
  .dropdown__item__status--unavailable {
    background-color: #ffeb3b; }

.dropdown__empty-list {
  padding: 16px;
  text-align: center;
  font-size: 13px;
  color: gray;
  font-weight: 700; }

.dropdown .ui.dropdown.button {
  background-color: #00b5ad; }
  .dropdown .ui.dropdown.button .icon {
    padding-left: 14px;
    color: white; }

.dropdown .ui.dropdown > .text {
  color: white; }

.dropdown .ui.dropdown .menu {
  position: absolute;
  right: 0;
  left: auto;
  top: 40px; }
  .dropdown .ui.dropdown .menu .item.dropdown__item {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.ui.vertical.menu .item .text-icon {
  align-self: center;
  padding-left: 4px; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.main-container {
  display: flex;
  height: 100%;
  padding-top: 4em; }
  .main-container__sidebar.ui.left.sidebar {
    width: 14em; }
  .main-container__sidebar.menu .item {
    padding: 16px 12px; }
  .main-container__sidebar.ui.accordion .accordion {
    margin: 0; }
  .main-container .ui.visible.left.sidebar ~ .pusher {
    overflow: auto;
    height: 100%; }
  .main-container__sidemenu {
    overflow: hidden;
    z-index: 99;
    width: 14em;
    flex: 0 0 14em;
    transition: width 0.3s, flex 0.3s; }
    .main-container__sidemenu .ui[class*="left fixed"].menu {
      top: 4em; }
    .main-container__sidemenu .ui.vertical.menu {
      width: 14em;
      background: var(--bg-frame); }
      .main-container__sidemenu .ui.vertical.menu .item .menu .item,
      .main-container__sidemenu .ui.vertical.menu .title,
      .main-container__sidemenu .ui.vertical.menu .item {
        font-weight: bold; }
    .main-container__sidemenu.menu .item {
      padding: 16px 12px; }
      .main-container__sidemenu.menu .item i {
        margin-right: 0;
        transition: margin-right 300ms; }
    .main-container__sidemenu--shrunk {
      width: 3.6em !important;
      flex: 0 0 3.6em; }
      .main-container__sidemenu--shrunk .ui.vertical.menu {
        width: 3.6em; }
      .main-container__sidemenu--shrunk .item i {
        margin-right: 24px; }
  .main-container__content {
    flex: 1 1 100%;
    padding: 2em 1em;
    background-color: var(--bg-main); }
    .main-container__content::-webkit-scrollbar {
      width: 0.8em; }
    .main-container__content .attached.tabular.menu .item:hover {
      color: #6fb4c8; }
    .main-container__content--no-padding {
      padding: 0; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.top-menu {
  height: 4em; }
  .top-menu .dropdown .ui.dropdown.button {
    background-color: #018605; }
    .top-menu .dropdown .ui.dropdown.button:hover {
      background-color: #004c03; }
  .top-menu__logo-space-menu-item {
    width: 14em; }
    .top-menu__logo-space-menu-item__admin-panel {
      display: inline-flex;
      align-items: center;
      font-family: "Roboto Light", serif;
      font-size: 24px;
      margin: 0 auto; }
      .top-menu__logo-space-menu-item__admin-panel img {
        margin-right: 8px; }
  .top-menu__no-border::before {
    display: none; }
  .top-menu__header-right {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    align-items: center; }
    .top-menu__header-right__logged-as {
      font-weight: bold; }
      .top-menu__header-right__logged-as__user {
        padding-left: 5px;
        color: #00b5ad; }
    .top-menu__header-right__item::before {
      display: none; }
    .top-menu__header-right--mobile {
      margin-right: 1em; }
      .top-menu__header-right--mobile .ui.dropdown .menu .left.menu,
      .top-menu__header-right--mobile .ui.dropdown > .left.menu .menu {
        left: 0;
        right: 0;
        margin: 37px -0.5em 0 0 !important; }
      .top-menu__header-right--mobile .ui.dropdown .item .left.dropdown.icon,
      .top-menu__header-right--mobile .ui.dropdown .left.menu .item .dropdown.icon {
        margin: -0.78571429em; }
      .top-menu__header-right--mobile .ui.dropdown .left.menu .item .dropdown.icon:before {
        content: "\f0d7"; }

.ui.menu.top-menu--simulation {
  background-color: #fff5ef; }

.simulation-header {
  display: flex;
  margin: auto;
  align-items: baseline; }
  .simulation-header__title {
    font-weight: 700;
    font-size: large;
    color: #00b5ad; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.bot-status {
  min-width: 240px;
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content; }
  .bot-status__item {
    width: 100%; }
    .bot-status__item__title {
      display: flex;
      align-items: center;
      grid-gap: 16px;
      gap: 16px;
      padding: 12px 16px;
      background-color: var(--bg-frame);
      color: #6fb4c8;
      border-bottom: 1px solid;
      font-size: 16px; }
      .bot-status__item__title__status {
        width: 10px;
        height: 10px;
        border-radius: 50%; }
    .bot-status__item__content {
      width: 100%;
      padding: 12px 16px;
      font-weight: 700;
      font-size: 16px; }

.bot-status__dropdown-item {
  padding: 0 !important; }

.sk-dial-pad-product-selection {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  align-items: center; }
  .sk-dial-pad-product-selection > * {
    margin-bottom: 15px; }

.sk-dial-pad-modal {
  display: contents;
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */ }
  .sk-dial-pad-modal .sk-dial-pad-container {
    display: flex;
    align-content: center;
    flex-direction: column;
    background-color: white;
    margin: auto;
    height: 600px; }
    .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-buttons-container {
      height: 70%; }
      .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-buttons-container .sk-dial-pad-buttons-row {
        display: flex;
        flex-direction: row;
        height: 25%;
        align-items: baseline; }
      .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-buttons-container .sk-dial-pad-button {
        background-color: white;
        text-align: center;
        flex: 1 0 33%;
        border: none;
        font-size: 2em;
        outline: none;
        height: 100%; }
        .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-buttons-container .sk-dial-pad-button:hover {
          background-color: #00b5ad;
          color: white; }
        .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-buttons-container .sk-dial-pad-button .sk-dial-pad-button-content {
          position: relative; }
          .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-buttons-container .sk-dial-pad-button .sk-dial-pad-button-content > span {
            position: absolute;
            top: 100%;
            right: 50%;
            margin: 0;
            font-size: 0.5em;
            transform: translateX(50%);
            pointer-events: none; }
    .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-call-button {
      grid-column: 1 / span 3;
      border: none;
      background-color: #00b5ad;
      height: 12%; }
      .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-call-button > i {
        color: white;
        font-size: 1.5em; }
    .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-call-warning-zendeskId {
      display: flex;
      flex-direction: column;
      background-color: #00b5ad; }
      .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-call-warning-zendeskId p {
        text-align: center;
        margin-top: 5px; }
      .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-call-warning-zendeskId .button-group {
        display: flex;
        justify-content: space-evenly;
        margin-bottom: 5px; }
    .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-input-container {
      grid-column: 1 / span 3;
      text-align: center;
      display: flex;
      height: 18%; }
      .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-input-container .sk-dial-pad-input-side {
        display: flex;
        flex: 0 0 7%; }
      .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-input-container .sk-dial-pad-input-numbers {
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        flex: 0 0 90%;
        padding-left: 1em; }
        .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-input-container .sk-dial-pad-input-numbers input {
          width: 62%; }
        .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-input-container .sk-dial-pad-input-numbers .label {
          background-color: #00b5ad;
          color: white;
          border: 1px solid rgba(34, 36, 38, 0.15);
          width: 38%; }
        .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-input-container .sk-dial-pad-input-numbers .check {
          color: #00b5ad; }
        .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-input-container .sk-dial-pad-input-numbers .delete {
          color: red; }
        .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-input-container .sk-dial-pad-input-numbers .spinner {
          border-color: #00b5ad transparent transparent !important;
          border-top-color: #00b5ad;
          border-right-color: transparent;
          border-left-color: transparent;
          border-bottom-color: transparent; }
    .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-input {
      border: none;
      font-size: 1.5em;
      flex: 1 1 90%;
      text-align: center;
      outline: none; }
    .sk-dial-pad-modal .sk-dial-pad-container .sk-dial-pad-delete-button {
      border: none;
      background-color: transparent;
      color: black;
      font-size: 1.5em;
      flex: 0 0 5%;
      align-self: baseline; }

.sk-dial-pad-in-call-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: white;
  margin: auto;
  height: 600px; }
  .sk-dial-pad-in-call-container .img-container {
    display: flex;
    justify-content: center;
    margin-bottom: 0.2em;
    margin-top: 0.2em; }
  .sk-dial-pad-in-call-container .sk-timmer-container {
    margin: 0.2em 0; }
    .sk-dial-pad-in-call-container .sk-timmer-container .sk-timmer-label {
      color: #00b5ad; }
  .sk-dial-pad-in-call-container h5 {
    text-align: center;
    color: #00b5ad;
    font-size: 1.5em;
    margin: 0.2em 0; }
  .sk-dial-pad-in-call-container h6 {
    color: #00b5ad;
    font-size: 1.5em;
    text-align: center;
    margin: 0.2em 0;
    flex: 0 0 5%; }
  .sk-dial-pad-in-call-container .sk-dial-pad-buttons-container {
    min-height: 320px; }
    .sk-dial-pad-in-call-container .sk-dial-pad-buttons-container .sk-dial-pad-buttons-row {
      display: flex;
      flex-direction: row;
      height: 25%;
      align-items: baseline; }
    .sk-dial-pad-in-call-container .sk-dial-pad-buttons-container .sk-dial-pad-button {
      background-color: #00b5ad;
      text-align: center;
      flex: 1 0 33%;
      border: none;
      font-size: 2em;
      outline: none;
      height: 100%; }
      .sk-dial-pad-in-call-container .sk-dial-pad-buttons-container .sk-dial-pad-button:hover {
        background-color: #00b5ad;
        color: white; }
      .sk-dial-pad-in-call-container .sk-dial-pad-buttons-container .sk-dial-pad-button .sk-dial-pad-button-content {
        position: relative; }
        .sk-dial-pad-in-call-container .sk-dial-pad-buttons-container .sk-dial-pad-button .sk-dial-pad-button-content > span {
          position: absolute;
          top: 100%;
          right: 50%;
          margin: 0;
          font-size: 0.5em;
          transform: translateX(50%);
          pointer-events: none; }
  .sk-dial-pad-in-call-container .sk-dial-pad-in-call-info-container {
    flex: 1 1 80%;
    display: flex;
    flex-direction: column; }
    .sk-dial-pad-in-call-container .sk-dial-pad-in-call-info-container > img {
      align-self: flex-start; }
  .sk-dial-pad-in-call-container .sk-dial-pad-end-call-button {
    border: none;
    background-color: white;
    height: 60px; }
    .sk-dial-pad-in-call-container .sk-dial-pad-end-call-button > i {
      color: red;
      font-size: 1.5em;
      transform: rotate(-135deg); }

.sk-dial-pad-ringing-container {
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: white;
  margin: auto;
  height: 600px;
  background-color: #00b5ad; }
  .sk-dial-pad-ringing-container .sk-dial-pad-ringing-info-container {
    flex: 1 1 80%;
    display: flex;
    flex-direction: column; }
    .sk-dial-pad-ringing-container .sk-dial-pad-ringing-info-container > img {
      align-self: flex-start; }
  .sk-dial-pad-ringing-container .sk-dial-pad-menage-incoming-call-buttons {
    display: flex;
    justify-content: space-between;
    flex: 1 1 20%;
    background-color: white; }
    .sk-dial-pad-ringing-container .sk-dial-pad-menage-incoming-call-buttons .accept-call {
      color: white;
      margin-left: 3em;
      margin-top: 1.5em;
      width: 80px;
      height: 80px;
      padding: 0; }
      .sk-dial-pad-ringing-container .sk-dial-pad-menage-incoming-call-buttons .accept-call > i {
        font-size: 1.5em; }
    .sk-dial-pad-ringing-container .sk-dial-pad-menage-incoming-call-buttons .reject-call {
      color: white;
      margin-right: 3em;
      margin-top: 1.5em;
      width: 80px;
      height: 80px;
      padding: 0; }
      .sk-dial-pad-ringing-container .sk-dial-pad-menage-incoming-call-buttons .reject-call > i {
        font-size: 1.5em;
        transform: rotate(-135deg); }

.phone {
  position: relative;
  display: block;
  margin: 0;
  width: 80px;
  height: 80px;
  font-size: 25vmin;
  border-radius: 80px;
  box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2);
  transform: translate3d(0, 0, 0) scale(1); }

.phone.accept-call-icon {
  background-color: limegreen; }

.phone.reject-call-icon {
  background-color: red; }

.phone::before,
.phone::after {
  position: absolute;
  content: ""; }

.phone::before {
  top: 10px;
  left: 10px;
  width: 3em;
  height: 3em;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  opacity: 1;
  transform: translate3d(0, 0, 0) scale(0); }

.phone::after {
  top: 30px;
  left: 30px;
  width: 1em;
  height: 1em;
  background: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAyNCAyNCI+PHBhdGggZD0iTTYuNiAxMC44YzEuNCAyLjggMy44IDUuMSA2LjYgNi42bDIuMi0yLjJjLjMtLjMuNy0uNCAxLS4yIDEuMS40IDIuMy42IDMuNi42LjUgMCAxIC40IDEgMVYyMGMwIC41LS41IDEtMSAxLTkuNCAwLTE3LTcuNi0xNy0xNyAwLS42LjQtMSAxLTFoMy41Yy41IDAgMSAuNCAxIDEgMCAxLjIuMiAyLjUuNiAzLjYuMS40IDAgLjctLjIgMWwtMi4zIDIuMnoiIGZpbGw9IiNmZmZmZmYiLz48L3N2Zz4=);
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  transform: translate3d(0, 0, 0); }

.phone.is-animating {
  -webkit-animation: phone-outer 3000ms infinite;
          animation: phone-outer 3000ms infinite; }
  .phone.is-animating::before {
    -webkit-animation: phone-inner 3000ms infinite;
            animation: phone-inner 3000ms infinite; }
  .phone.is-animating::after {
    -webkit-animation: phone-icon 3000ms infinite;
            animation: phone-icon 3000ms infinite; }

@-webkit-keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2); }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0.1), 0em 0.05em 0.1em rgba(0, 0, 0, 0.5); }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0.5em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2); }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2); } }

@keyframes phone-outer {
  0% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2); }
  33.3333% {
    transform: translate3d(0, 0, 0) scale(1.1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0.1), 0em 0.05em 0.1em rgba(0, 0, 0, 0.5); }
  66.6666% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0.5em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2); }
  100% {
    transform: translate3d(0, 0, 0) scale(1);
    box-shadow: 0 0 0 0em rgba(52, 152, 219, 0), 0em 0.05em 0.1em rgba(0, 0, 0, 0.2); } }

@-webkit-keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0); }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9); }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0); }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0); } }

@keyframes phone-inner {
  0% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0); }
  33.3333% {
    opacity: 1;
    transform: translate3d(0, 0, 0) scale(0.9); }
  66.6666% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0); }
  100% {
    opacity: 0;
    transform: translate3d(0, 0, 0) scale(0); } }

@-webkit-keyframes phone-icon {
  0% {
    transform: translate3d(0em, 0, 0); }
  2% {
    transform: translate3d(0.01em, 0, 0); }
  4% {
    transform: translate3d(-0.01em, 0, 0); }
  6% {
    transform: translate3d(0.01em, 0, 0); }
  8% {
    transform: translate3d(-0.01em, 0, 0); }
  10% {
    transform: translate3d(0.01em, 0, 0); }
  12% {
    transform: translate3d(-0.01em, 0, 0); }
  14% {
    transform: translate3d(0.01em, 0, 0); }
  16% {
    transform: translate3d(-0.01em, 0, 0); }
  18% {
    transform: translate3d(0.01em, 0, 0); }
  20% {
    transform: translate3d(-0.01em, 0, 0); }
  22% {
    transform: translate3d(0.01em, 0, 0); }
  24% {
    transform: translate3d(-0.01em, 0, 0); }
  26% {
    transform: translate3d(0.01em, 0, 0); }
  28% {
    transform: translate3d(-0.01em, 0, 0); }
  30% {
    transform: translate3d(0.01em, 0, 0); }
  32% {
    transform: translate3d(-0.01em, 0, 0); }
  34% {
    transform: translate3d(0.01em, 0, 0); }
  36% {
    transform: translate3d(-0.01em, 0, 0); }
  38% {
    transform: translate3d(0.01em, 0, 0); }
  40% {
    transform: translate3d(-0.01em, 0, 0); }
  42% {
    transform: translate3d(0.01em, 0, 0); }
  44% {
    transform: translate3d(-0.01em, 0, 0); }
  46% {
    transform: translate3d(0em, 0, 0); } }

@keyframes phone-icon {
  0% {
    transform: translate3d(0em, 0, 0); }
  2% {
    transform: translate3d(0.01em, 0, 0); }
  4% {
    transform: translate3d(-0.01em, 0, 0); }
  6% {
    transform: translate3d(0.01em, 0, 0); }
  8% {
    transform: translate3d(-0.01em, 0, 0); }
  10% {
    transform: translate3d(0.01em, 0, 0); }
  12% {
    transform: translate3d(-0.01em, 0, 0); }
  14% {
    transform: translate3d(0.01em, 0, 0); }
  16% {
    transform: translate3d(-0.01em, 0, 0); }
  18% {
    transform: translate3d(0.01em, 0, 0); }
  20% {
    transform: translate3d(-0.01em, 0, 0); }
  22% {
    transform: translate3d(0.01em, 0, 0); }
  24% {
    transform: translate3d(-0.01em, 0, 0); }
  26% {
    transform: translate3d(0.01em, 0, 0); }
  28% {
    transform: translate3d(-0.01em, 0, 0); }
  30% {
    transform: translate3d(0.01em, 0, 0); }
  32% {
    transform: translate3d(-0.01em, 0, 0); }
  34% {
    transform: translate3d(0.01em, 0, 0); }
  36% {
    transform: translate3d(-0.01em, 0, 0); }
  38% {
    transform: translate3d(0.01em, 0, 0); }
  40% {
    transform: translate3d(-0.01em, 0, 0); }
  42% {
    transform: translate3d(0.01em, 0, 0); }
  44% {
    transform: translate3d(-0.01em, 0, 0); }
  46% {
    transform: translate3d(0em, 0, 0); } }

.sk-dial-pad-ringing-props {
  margin: auto;
  padding: 12px;
  border-radius: 20px;
  background-color: white;
  font-size: 1.2em;
  text-align: center; }

.sk-timmer-container {
  display: flex;
  justify-content: center; }
  .sk-timmer-container .sk-timmer-label {
    font-size: 24px;
    color: white; }

.sk-message-popup-modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  width: 100%;
  /* Full width */
  height: 100%;
  /* Full height */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  display: contents; }
  .sk-message-popup-modal .sk-message-popup-container {
    display: flex;
    background-color: white;
    width: 350px;
    height: 600px;
    margin: auto;
    align-content: center; }
    .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form {
      width: 100%;
      height: 600px; }
      .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-dial-pad-input-numbers {
        display: flex;
        flex-direction: column;
        flex: 0 0 90%;
        padding-right: 0.2em;
        padding-left: 0.3em; }
        .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-dial-pad-input-numbers input {
          width: 62%; }
        .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-dial-pad-input-numbers .label {
          background-color: #00b5ad;
          color: white;
          border: 1px solid rgba(34, 36, 38, 0.15);
          width: 38%; }
        .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-dial-pad-input-numbers .check {
          color: #00b5ad; }
        .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-dial-pad-input-numbers .delete {
          color: red; }
        .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-dial-pad-input-numbers .spinner {
          border-color: #00b5ad transparent transparent !important;
          border-top-color: #00b5ad;
          border-right-color: transparent;
          border-left-color: transparent;
          border-bottom-color: transparent; }
      .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-message-input-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 15%;
        padding-top: 5px; }
      .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .input {
        margin: 5px;
        color: yellow; }
        .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .input input {
          width: auto; }
      .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-message-popup-textarea-container {
        display: flex;
        justify-content: center;
        height: 75%;
        padding-top: 5px; }
        .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-message-popup-textarea-container .field {
          display: flex;
          width: 100%;
          height: 100%;
          justify-content: center; }
      .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form textarea {
        width: 95%;
        height: 100%;
        resize: none; }
      .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-message-button-group {
        padding-top: 5px;
        padding-bottom: 5px;
        display: flex;
        width: 100%;
        justify-content: space-around;
        height: 10%; }
        .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-message-button-group .sk-message-send-button {
          background-color: #00b5ad;
          color: white;
          width: 35%; }
        .sk-message-popup-modal .sk-message-popup-container .sk-message-popup-form .sk-message-button-group .sk-message-cancel-button {
          background-color: #db2828;
          color: white;
          width: 35%; }

.sk-incoming-call-glider {
  display: flex;
  overflow: hidden;
  flex-direction: column;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  padding: 0 0 20px 20px;
  background-color: #c0c0c0;
  position: fixed;
  z-index: 5;
  border-radius: 20px;
  right: 0;
  top: 0; }
  .sk-incoming-call-glider > .sk-incoming-call-row {
    display: flex; }
    .sk-incoming-call-glider > .sk-incoming-call-row:nth-child(1) {
      justify-content: flex-end; }
    .sk-incoming-call-glider > .sk-incoming-call-row:nth-child(2) {
      justify-content: space-evenly; }
    .sk-incoming-call-glider > .sk-incoming-call-row > .button {
      margin: 0;
      height: 40px; }
    .sk-incoming-call-glider > .sk-incoming-call-row > .call-button {
      border-radius: 50% !important;
      height: 50px !important;
      width: 50px !important; }

.sk-incoming-call-minimized-button {
  width: 50px;
  height: 50px;
  background-color: #c0c0c0 !important;
  position: fixed !important;
  right: 0;
  top: 0 !important;
  z-index: 5;
  border-radius: 20px; }

.sk-call-participant {
  align-self: center;
  font-size: 1.3em;
  margin: auto; }

.sk-top-margin {
  margin-top: 20px; }

.modal-container {
  height: 600px; }
  .modal-container.ui.modal {
    width: 350px; }
  .modal-container i {
    font-size: 1.2em; }
  .modal-container .search {
    font-size: 1em; }

.sk-call-button {
  border-radius: 50%;
  height: 50px;
  width: 50px;
  border: none;
  background-color: #00b5ad;
  cursor: pointer;
  outline: none; }
  .sk-call-button > i {
    color: white;
    font-size: 1.2em; }
  .sk-call-button:hover {
    box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }
  .sk-call-button.force-left-margin {
    margin-left: 15px !important; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.ui.modal {
  border-radius: 8px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content; }
  @media (max-width: 568px) {
    .ui.modal {
      bottom: 0;
      border-radius: 5px 5px 0 0; } }
  .ui.modal > .header {
    background-color: var(--bg-form-frame);
    color: var(--modal-form-color); }
  .ui.modal > .content,
  .ui.modal > .content .ui.form .field label {
    background-color: var(--bg-form-content);
    color: var(--syntax-main); }
  .ui.modal > .actions {
    background-color: var(--bg-form-frame); }

.ui.modal > .notifications {
  padding: 0 !important;
  max-height: 50vh;
  overflow: auto; }

.ui.modal .notification {
  cursor: pointer;
  padding: 0.98571429em 1.41285714em; }
  .ui.modal .notification:hover {
    background-color: #f5f5f5; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.notifications--dropdown {
  position: relative; }
  .notifications--dropdown .ui.dropdown .menu:not(.scrolling) {
    right: 0;
    left: auto;
    max-height: 560px;
    overflow: auto;
    margin-top: 14px;
    min-width: 240px;
    border: 1px solid var(--syntax-main-inverse); }
    @media (max-width: 568px) {
      .notifications--dropdown .ui.dropdown .menu:not(.scrolling) {
        position: fixed;
        margin-top: 0;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 100%; } }
  @media (max-width: 568px) {
    .notifications--dropdown .ui.dropdown .menu.visible:not(.scrolling) {
      border-radius: 0;
      display: flex !important;
      flex-direction: column; } }
  @media (max-width: 568px) {
    .notifications--dropdown .ui.dropdown .menu.scrolling {
      max-height: none; } }
  .notifications--dropdown > .ui.circular.label {
    bottom: 12px;
    left: 12px;
    position: absolute; }
  .notifications--dropdown__subheader {
    display: flex;
    justify-content: space-between;
    background-color: var(--bg-frame);
    color: #6fb4c8;
    /** value copied from semantic */
    padding: 0.78571429em 0.57142812em 0.78571429em 1.14285714em; }
    .notifications--dropdown__subheader__modal-trigger {
      cursor: pointer; }
  .notifications--dropdown__title {
    flex-grow: 1; }
    .notifications--dropdown__title + div > button {
      padding: 0; }
    .notifications--dropdown__title + div .icon.delete {
      margin-left: 8px; }
      @media (min-width: 768px) {
        .notifications--dropdown__title + div .icon.delete {
          display: none; } }
  .notifications--dropdown .ui.dropdown .scrolling.menu .item {
    border-bottom: 1px solid #b1ebeb; }
  .notifications--dropdown__message__ticket {
    font-size: 12px;
    margin-top: 4px;
    color: gray; }
  .notifications--dropdown__empty-list {
    padding: 16px;
    text-align: center;
    font-size: 13px;
    color: gray;
    font-weight: 700; }

.notification,
.ui.modal .notification,
.ui.dropdown .notification {
  display: flex;
  align-items: center; }
  .notification--unread,
  .ui.modal .notification--unread,
  .ui.dropdown .notification--unread {
    font-weight: bold; }
    .notification--unread.red,
    .ui.modal .notification--unread.red,
    .ui.dropdown .notification--unread.red {
      background-color: rgba(255, 0, 0, 0.1); }
      .notification--unread.red:hover,
      .ui.modal .notification--unread.red:hover,
      .ui.dropdown .notification--unread.red:hover {
        background-color: rgba(255, 0, 0, 0.15); }
    .notification--unread.green,
    .ui.modal .notification--unread.green,
    .ui.dropdown .notification--unread.green {
      background-color: rgba(0, 181, 173, 0.1); }
      .notification--unread.green:hover,
      .ui.modal .notification--unread.green:hover,
      .ui.dropdown .notification--unread.green:hover {
        background-color: rgba(0, 181, 173, 0.15); }
    .notification--unread:not(.green):not(.red),
    .ui.modal .notification--unread:not(.green):not(.red),
    .ui.dropdown .notification--unread:not(.green):not(.red) {
      background-color: rgba(128, 128, 128, 0.1); }
      .notification--unread:not(.green):not(.red):hover,
      .ui.modal .notification--unread:not(.green):not(.red):hover,
      .ui.dropdown .notification--unread:not(.green):not(.red):hover {
        background-color: rgba(128, 128, 128, 0.15); }
  .notification__text,
  .ui.modal .notification__text,
  .ui.dropdown .notification__text {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.zendesk-top-menu {
  height: 4em;
  justify-content: center;
  background-color: var(--bg-segment-color) !important; }
  .zendesk-top-menu .item {
    padding: 13px 13px !important; }
  .zendesk-top-menu > .item {
    padding: 13px 40px !important; }
  .zendesk-top-menu > .ui.button.blue {
    border-color: gray; }
  .zendesk-top-menu .dropdown .ui.dropdown.button {
    background-color: #018605; }
    .zendesk-top-menu .dropdown .ui.dropdown.button:hover {
      background-color: #004c03; }
  .zendesk-top-menu__no-border::before {
    display: none; }
  .zendesk-top-menu__header-right {
    display: flex;
    flex: 1 1;
    justify-content: flex-end;
    align-items: center;
    width: 50%; }
    .zendesk-top-menu__header-right .selection.dropdown {
      min-width: 10em; }
    .zendesk-top-menu__header-right__logged-as {
      font-weight: bold !important;
      color: #6fb4c8 !important; }
      .zendesk-top-menu__header-right__logged-as__user {
        padding-left: 5px;
        color: #00b5ad; }
    .zendesk-top-menu__header-right__item::before {
      display: none; }
    .zendesk-top-menu__header-right__log-out:hover .text-icon {
      color: white; }
    .zendesk-top-menu__header-right__log-out .icon {
      color: #6fb4c8; }
    .zendesk-top-menu__header-right__log-out .text-icon {
      font-weight: bold;
      line-height: normal;
      line-height: initial;
      color: #6fb4c8; }

.icon-container {
  background-color: transparent;
  border-radius: 50%;
  display: inline-block;
  padding: 6px; }

.icon-container:hover {
  background-color: red; }

.icon-container i {
  margin: 0;
  color: #00b5ad; }

.icon-container:hover i {
  margin: 0;
  color: white; }

.modal-form {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  z-index: 1000; }
  .modal-form__body {
    background-color: var(--bg-main);
    color: var(--modal-form-color);
    padding: 16px;
    position: absolute;
    max-height: 95%;
    overflow: auto; }
    @media (max-width: 568px) {
      .modal-form__body {
        overflow: auto;
        border-radius: 5px 5px 0 0;
        bottom: 0;
        width: 100%; } }
    @media (min-width: 768px) {
      .modal-form__body {
        border-radius: 5px;
        width: 800px;
        max-width: 90%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }

.ui.button.modal-form__button {
  color: white;
  background-color: var(--modal-form-color); }

.ui.small.modal.popup-basic-class > div {
  background-color: var(--bg-main); }

.ui.small.modal.delete-popup > .header {
  color: #af0000; }

.ui.primary.button.confirm-popup {
  background-color: var(--modal-form-color);
  margin: 0; }

.ui.primary.button.delete-popup {
  background-color: #af0000;
  margin: 0; }

.ui.form .disabled.field {
  opacity: 1; }

.ui.form .field label {
  color: var(--modal-form-color); }

.credit-card-group {
  display: flex;
  justify-content: space-between;
  margin: 0 -8px; }
  .credit-card-group > div {
    flex-grow: 1;
    padding: 0 8px; }

.ui.form .ui.input > input:focus,
.ui.form textarea:focus {
  background-color: var(--bg-dropdown);
  color: var(--syntax-main); }

.ui.inverted.dimmer {
  background-color: rgba(0, 0, 0, 0.5); }
  .ui.inverted.dimmer .ui.loader.text {
    color: var(--syntax-main); }

.ui.items > .item > .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .ui.items > .item > .content .description > .dropdown {
    width: 80%; }

.bot-autosubmit-content-wrapper,
.bot-statuschecker-content-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%; }
  .bot-autosubmit-content-wrapper .bot-autosubmit-toggle,
  .bot-statuschecker-content-wrapper .bot-autosubmit-toggle {
    margin-right: 50px;
    display: flex; }
    .bot-autosubmit-content-wrapper .bot-autosubmit-toggle > *,
    .bot-statuschecker-content-wrapper .bot-autosubmit-toggle > * {
      margin-right: 10px; }
  .bot-autosubmit-content-wrapper .bot-autosubmit-input > .ui.input,
  .bot-statuschecker-content-wrapper .bot-autosubmit-input > .ui.input {
    margin-right: 20px;
    width: 100px; }

.toggle::after {
  display: none; }

.bot-item-container .item .content .meta,
.bot-item-container .item .content .bot-autosubmit-input,
.bot-item-container .item .content .bot-autosubmit-toggle,
.bot-item-container .item .content .bot-statuschecker-input,
.bot-item-container .item .content .bot-statuschecker-toggle,
.bot-item-container .item .content .ui.dropdown .text {
  color: var(--syntax-main); }

.bot-item-container .item .content .ui.dropdown .menu .item .description {
  color: grey; }

.bot-next-attempt {
  display: flex;
  flex-direction: column;
  color: white;
  margin: 8px 0; }
  .bot-next-attempt__input {
    margin-top: 8px; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.ticket-input {
  min-height: 400px; }

.zendesk-configuration .ui.fluid.vertical.menu .item,
.zendesk-configuration .ui.fluid.vertical.menu .active.item {
  background-color: var(--bg-main);
  border-bottom: 1px solid var(--border-theme); }

.zendesk-configuration .ui.fluid.vertical.menu .item {
  color: var(--syntax-main); }

.zendesk-configuration .ui.fluid.vertical.menu .active.item {
  color: #6fb4c8; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.ui.divided.items.alarms-tab .ui.horizontal.list {
  display: flex;
  justify-content: space-between;
  align-items: flex-start; }

.ui.divided.items.alarms-tab .email-container {
  display: flex;
  justify-content: space-evenly;
  margin-top: 10px;
  padding-top: 10px;
  border-top: 1px solid var(--syntax-main); }

.ui.divided.items.alarms-tab .email-container-email {
  display: flex;
  flex-direction: column;
  border: 1px solid var(--syntax-main);
  padding: 10px;
  border-radius: 0.28571rem;
  width: 70%; }
  .ui.divided.items.alarms-tab .email-container-email > div.input {
    margin-bottom: 15px; }
  .ui.divided.items.alarms-tab .email-container-email > textarea {
    border-radius: 0.28571rem;
    border-color: var(--syntax-main);
    padding: 10px; }

.ui.divided.items.alarms-tab .email-container-legenda {
  border: 1px solid var(--syntax-main);
  padding: 10px;
  border-radius: 0.28571rem;
  color: var(--syntax-main); }
  .ui.divided.items.alarms-tab .email-container-legenda > div {
    height: 40px;
    border-radius: 10px;
    padding: 10px; }
    .ui.divided.items.alarms-tab .email-container-legenda > div:hover {
      background-color: grey;
      color: white;
      cursor: pointer; }

.ui.divided.items.alarms-tab .item .content .header:not(.ui) {
  color: #00b5ad; }

.ui.divided.items.alarms-tab .item .content .ui.horizontal.list .item .meta,
.ui.divided.items.alarms-tab .item .content .ui.horizontal.list .item label,
.ui.divided.items.alarms-tab .item .content .ui.horizontal.list .item .description .item {
  color: var(--syntax-main); }

.ui.divided.items.alarms-tab > .item:not(:first-child) {
  border-top: 1px solid var(--syntax-main); }

.applicants-deletion__table-header {
  display: flex;
  flex-direction: row;
  justify-content: space-between; }
  .applicants-deletion__table-header > .header {
    width: 50% !important; }
  .applicants-deletion__table-header .users-filters {
    grid-gap: 8px;
    gap: 8px; }
    .applicants-deletion__table-header .users-filters .field input {
      padding-left: 1em !important; }

.ui.items > .item > .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .ui.items > .item > .content .description > .dropdown {
    width: 80%; }

.ivisa-content-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%; }
  .ivisa-content-wrapper .manual-submit-toggle {
    margin-right: 50px;
    display: flex; }
    .ivisa-content-wrapper .manual-submit-toggle > * {
      margin-right: 10px; }
  .ivisa-content-wrapper .daily-quota-input > .ui.input {
    margin-right: 20px;
    width: 100px; }

.toggle::after {
  display: none; }

.daily-quota-input,
.manual-submit-toggle,
.item,
.meta,
.text {
  color: var(--syntax-main); }

.ivisa-content-wrapper .item .content .ui.dropdown .menu .item .description {
  color: grey; }

.ui.items > .item > .content {
  display: flex;
  flex-direction: column;
  justify-content: flex-end; }
  .ui.items > .item > .content .description > .dropdown {
    width: 80%; }

.document-content-wrapper {
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  width: 100%; }
  .document-content-wrapper .manual-submit-toggle {
    margin-right: 50px;
    display: flex; }
    .document-content-wrapper .manual-submit-toggle > * {
      margin-right: 10px; }
  .document-content-wrapper .expiration-days-input > .ui.input {
    margin-right: 20px;
    width: 100px; }

.toggle::after {
  display: none; }

.expiration-days-input,
.manual-submit-toggle,
.item,
.meta,
.text {
  color: var(--syntax-main); }

.document-content-wrapper .item .content .ui.dropdown .menu .item .description {
  color: grey; }

.product-config {
  border: 1px solid white !important;
  grid-gap: 32px;
  gap: 32px;
  display: flex;
  flex-direction: column; }
  .product-config__item {
    display: flex;
    align-items: center; }
    .product-config__item > * {
      flex: 1 1 50%; }
    .product-config__item__title {
      color: #00b5ad !important;
      display: block;
      font-size: 16px;
      font-weight: 700; }

.bot-statistics-select {
  width: 250px; }

.bot-statistics-label {
  padding: 0px 10px;
  font-size: 15px; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.confirm {
  z-index: 120;
  top: 50%;
  left: 50%;
  position: fixed;
  transform: translate(-50%, -50%);
  background-color: white;
  border-radius: 6px;
  overflow: hidden; }
  .confirm__content {
    font-size: 1.2rem;
    background-color: #00b5ad;
    color: white; }
  .confirm__actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: stretch; }
    .confirm__actions > button {
      min-width: 80px; }
      .confirm__actions > button.decline {
        color: gray; }
        .confirm__actions > button.decline:hover {
          text-decoration: underline; }
      .confirm__actions > button + .confirm__actions > button {
        margin-left: 8px; }
  .confirm > * {
    padding: 16px 24px; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.statistic-buttons {
  display: flex;
  flex-direction: column;
  grid-gap: 10px;
  gap: 10px; }

.bot-config-segment {
  width: 100%;
  text-align: center; }

.select-product-dropdown {
  margin-bottom: 10px; }

.bot-edit-modal {
  width: 450px !important; }

.email-input-wrapper {
  display: flex;
  align-items: center;
  grid-gap: 5px;
  gap: 5px; }

.ui.green.button.enable-alarm-button {
  margin-bottom: 20px; }

.bot-alarms-label {
  padding: 0px 10px;
  font-size: 15px; }

.bot-alarms-select {
  width: 200px; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.qa-ticket-page-ticket-info {
  border-radius: 10px;
  border: solid gray 1px;
  padding: 5px;
  text-align: center;
  font-size: 1.3em; }

.qa-ticket-page-section {
  border: solid gray 1px;
  padding: 10px;
  border-radius: 10px;
  margin: 10px; }
  .qa-ticket-page-section > h3 {
    margin: 0 2.5%; }
  .qa-ticket-page-section__subheader {
    display: flex;
    justify-content: space-between;
    margin: 0 2.5%; }
    .qa-ticket-page-section__subheader .ui.dropdown.search.agent-ticket-dropdown {
      background: var(--bg-dropdown);
      background-color: rgba(0, 0, 0, 0.2); }
      .qa-ticket-page-section__subheader .ui.dropdown.search.agent-ticket-dropdown input[type="text"],
      .qa-ticket-page-section__subheader .ui.dropdown.search.agent-ticket-dropdown .text,
      .qa-ticket-page-section__subheader .ui.dropdown.search.agent-ticket-dropdown .message {
        color: var(--syntax-main); }
      .qa-ticket-page-section__subheader .ui.dropdown.search.agent-ticket-dropdown .menu {
        background: var(--bg-dropdown); }
        .qa-ticket-page-section__subheader .ui.dropdown.search.agent-ticket-dropdown .menu .item {
          color: var(--syntax-main);
          border-bottom: gray;
          display: flex;
          justify-content: space-between;
          align-items: center;
          -webkit-user-select: none;
             -moz-user-select: none;
              -ms-user-select: none;
                  user-select: none;
          cursor: auto;
          background-color: rgba(0, 0, 0, 0.01);
          border-bottom: 1px solid grey; }

.dropdown-adding-agent {
  float: right; }

.qa-ticket-page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 16px 2.5% 0; }

.qa-ticket-page-question-container {
  display: inline-block;
  width: 100%; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.qa-ticket-page-question-item {
  width: 45%;
  float: left;
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-height: 80px;
  padding-left: 10px;
  border: solid gray 1px;
  margin: 16px 2.5%; }
  .qa-ticket-page-question-item > .qa-ticket-page-question-title {
    border: solid gray 1px;
    width: 200px;
    justify-self: flex-start;
    padding: 10px;
    margin: 0; }
  .qa-ticket-page-question-item > .qa-ticket-page-question-not-apply-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    align-items: center;
    align-self: flex-start; }
    .qa-ticket-page-question-item > .qa-ticket-page-question-not-apply-container > .qa-ticket-page-question-not-apply-label {
      border: solid gray 1px;
      border-right: none;
      border-top: none;
      padding: 10px; }

.qa-report-page {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 200px; }
  .qa-report-page__reports {
    display: flex;
    flex-direction: column;
    margin-right: 12px; }
    .qa-report-page__reports * {
      color: var(--syntax-main); }
    .qa-report-page__reports__below-overall {
      margin-top: 48px; }
      .qa-report-page__reports__below-overall--grid {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        gap: 12px; }
        .qa-report-page__reports__below-overall--grid > div {
          display: grid;
          grid-template-rows: 1fr 3fr; }
      .qa-report-page__reports__below-overall__report {
        margin-bottom: 48px; }
        .qa-report-page__reports__below-overall__report .general-report,
        .qa-report-page__reports__below-overall__report .cat-or-prod-qa-report__products__product,
        .qa-report-page__reports__below-overall__report .cat-and-prod-qa-report__products__product,
        .qa-report-page__reports__below-overall__report .cat-and-prod-qa-report__products__product__categories__category,
        .qa-report-page__reports__below-overall__report .dispute-qa-report {
          background-color: var(--bg-general-report);
          border: 1px solid #afafaf; }
        .qa-report-page__reports__below-overall__report__title {
          margin: 0 0 48px;
          display: flex;
          align-items: center; }
          .qa-report-page__reports__below-overall__report__title--center {
            justify-content: center;
            text-align: center; }
            .qa-report-page__reports__below-overall__report__title--center > span {
              margin-right: 0; }
          .qa-report-page__reports__below-overall__report__title__text {
            margin-right: 12px;
            color: var(--syntax-main); }
  .qa-report-page__filter {
    width: 200px;
    background-color: var(--bg-frame);
    border-radius: 8px; }
    .qa-report-page__filter * {
      color: #6fb4c8; }
    .qa-report-page__filter__config {
      position: relative;
      z-index: 100; }
      .qa-report-page__filter__config .ui.checkbox label {
        color: #6fb4c8; }
      .qa-report-page__filter__config .ui.checkbox.checked label {
        color: white; }
      .qa-report-page__filter__config .ui.selection.dropdown {
        margin-bottom: 4px; }
      .qa-report-page__filter__config > div {
        margin-bottom: 14px; }
    .qa-report-page__filter__accordion {
      position: -webkit-sticky;
      position: sticky;
      top: 70px;
      right: 0; }
      .qa-report-page__filter__accordion .ui.vertical.menu {
        background: var(--bg-frame);
        border-radius: 0; }
  .qa-report-page .apexcharts-legend-series {
    text-align: left; }

.overall-qa-report {
  display: flex;
  justify-content: space-evenly; }
  .overall-qa-report--column {
    flex-direction: column;
    margin-top: 48px; }
  .overall-qa-report--none {
    margin-top: 0; }
  .overall-qa-report__overall-reviews {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    text-align: center; }
    .overall-qa-report__overall-reviews > h2 {
      margin: 0 12px 0; }
  .overall-qa-report__success-ratio {
    margin-top: 48px;
    text-align: center;
    cursor: pointer; }
    .overall-qa-report__success-ratio > h2 {
      color: var(--syntax-main); }

.cat-or-prod-qa-report {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%; }
  .cat-or-prod-qa-report__products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    gap: 24px; }

.product {
  width: 100%;
  padding: 24px 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer; }
  .product__no-data {
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 75%;
    justify-content: center; }

.cat-and-prod-qa-report {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%; }
  .cat-and-prod-qa-report__products {
    display: flex;
    flex-direction: column; }
    .cat-and-prod-qa-report__products__product {
      width: 100%;
      display: flex;
      flex-direction: column;
      padding: 24px 12px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      margin-bottom: 24px; }
      .cat-and-prod-qa-report__products__product__categories {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        gap: 12px;
        margin-top: 6px; }
        .cat-and-prod-qa-report__products__product__categories--empty {
          grid-template-columns: 1fr; }

.category {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 24px 12px;
  cursor: pointer; }
  .category__no-data {
    align-self: center;
    display: flex;
    flex-direction: column;
    height: 75%;
    justify-content: center; }

.specific-filters {
  display: flex;
  flex-direction: column;
  padding-top: 12px;
  height: 398px;
  overflow-y: scroll; }
  .specific-filters__filter {
    display: flex;
    flex-direction: column;
    margin-bottom: 12px; }
    .specific-filters__filter__category-title {
      margin-bottom: 18px;
      font-weight: bold;
      text-align: center; }
    .specific-filters__filter__title {
      margin-bottom: 18px;
      margin-right: 6px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-weight: bold; }
    .specific-filters__filter__checkbox {
      margin-bottom: 6px; }
    .specific-filters__filter .ui.checkbox label {
      color: #6fb4c8; }
    .specific-filters__filter .ui.checkbox.checked label {
      color: white; }
  .specific-filters__filter:last-child {
    margin-bottom: 0; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.specific-filters .search-and-display {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-right: 8px;
  text-align: center; }
  .specific-filters .search-and-display__search input {
    width: 100%; }
  .specific-filters .search-and-display__search .input .prompt {
    background-color: white;
    color: black; }
  .specific-filters .search-and-display__search .result .result-selected {
    color: #6fb4c8;
    font-weight: bold; }
  .specific-filters .search-and-display__list {
    max-height: 328px;
    overflow-y: auto;
    background-color: #616161; }
    .specific-filters .search-and-display__list--all-selected {
      background-color: unset; }
      .specific-filters .search-and-display__list--all-selected .item .content .header {
        color: #6fb4c8; }
    .specific-filters .search-and-display__list__item {
      margin: 3px 0;
      display: flex;
      flex-direction: row-reverse;
      text-align: justify; }
      .specific-filters .search-and-display__list__item .content .header {
        color: white; }
      .specific-filters .search-and-display__list__item__icon {
        cursor: pointer;
        color: white; }
        .specific-filters .search-and-display__list__item__icon:before:hover {
          color: #6fb4c8; }

.dispute-qa-report {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 24px 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer; }

.general-report {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 24px 12px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer; }

.calendar-filters {
  display: flex;
  flex-direction: column; }
  .calendar-filters--row {
    flex-direction: row;
    align-items: center; }
  .calendar-filters__single-filters {
    display: flex;
    flex-direction: column;
    margin: 12px 0; }
    .calendar-filters__single-filters .ui.checkbox label {
      color: #6fb4c8; }
    .calendar-filters__single-filters__filter {
      margin-bottom: 6px; }
  .calendar-filters__custom-range-title {
    margin-bottom: 18px;
    font-weight: bold; }
    .calendar-filters__custom-range-title--row {
      margin-bottom: 0;
      font-weight: normal;
      margin-right: 6px; }
  .calendar-filters__custom-range-display {
    margin-top: 18px;
    font-weight: bold; }
    .calendar-filters__custom-range-display--row {
      margin-top: 0;
      font-weight: normal;
      margin-left: 6px; }

span .react-date-picker__calendar {
  z-index: 102; }

.calendar-filters__single-filters .ui.checkbox.checked label,
.react-date-picker__wrapper * {
  color: white; }

.react-date-picker__calendar .react-calendar * {
  color: white; }

.react-date-picker__calendar .react-calendar__navigation, .react-date-picker__calendar .react-calendar__viewContainer,
.react-date-picker__calendar .react-calendar button[disabled] {
  background-color: var(--bg-react-calendar);
  margin: 0; }

.react-date-picker__calendar .react-calendar .react-calendar__navigation button:hover {
  background-color: #6f6f76; }

.react-date-picker__calendar .react-calendar .react-calendar__tile--now {
  background-color: #363641; }

.react-date-picker__calendar .react-calendar .react-calendar__tile--range, .react-date-picker__calendar .react-calendar .react-calendar__tile--hover {
  background-color: #325c8f; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.billing-report-charts {
  display: flex;
  flex-direction: column; }
  .billing-report-charts__config {
    width: 200px; }
  .billing-report-charts__title {
    display: flex;
    align-items: center;
    margin: 48px 0; }
    .billing-report-charts__title__text {
      margin: 0 12px 0 0; }
  .billing-report-charts .tran-or-prod-report__products__product {
    background-color: var(--bg-general-report);
    border: 1px solid #afafaf; }

.tran-or-prod-report {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%; }
  .tran-or-prod-report__products {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 24px;
    gap: 24px; }
    .tran-or-prod-report__products__product {
      width: 100%;
      padding: 24px 12px;
      box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      cursor: pointer; }
      .tran-or-prod-report__products__product__no-data {
        align-self: center;
        display: flex;
        flex-direction: column;
        height: 75%;
        justify-content: center; }

.total-per-prod-report {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%; }
  .total-per-prod-report__product {
    width: 100%;
    padding: 24px 12px;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    cursor: pointer; }
    .total-per-prod-report__product__no-data {
      align-self: center;
      display: flex;
      flex-direction: column;
      height: 75%;
      justify-content: center; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.billing-report {
  display: flex;
  flex-direction: column; }
  .billing-report * {
    color: var(--syntax-main); }
  .billing-report__config {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 48px; }
    .billing-report__config__input {
      margin: 24px 24px 12px 0; }
      .billing-report__config__input .billing-report-dropdown {
        padding: 8px 16px;
        border: 1px solid #afafaf; }
        .billing-report__config__input .billing-report-dropdown div {
          background-color: var(--bg-frame); }
      .billing-report__config__input__item {
        text-transform: capitalize; }
        .billing-report__config__input__item--selected {
          color: #00b5ad; }
    .billing-report__config__input:last-child {
      margin: 24px 0 12px 0; }
  .billing-report__results {
    display: grid; }
    .billing-report__results__view-select {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px; }
      .billing-report__results__view-select__date-display {
        margin-top: 48px;
        font-weight: bold; }
      .billing-report__results__view-select__percentage-fields {
        display: flex;
        font-weight: bold;
        font-size: 20px;
        margin-top: 48px;
        text-align: center; }
        .billing-report__results__view-select__percentage-fields > span:first-child {
          margin-right: 36px; }
    .billing-report__results__table-view {
      overflow-x: auto; }
      .billing-report__results__table-view__table {
        margin-top: 48px; }
        .billing-report__results__table-view__table__popup-details {
          display: flex;
          flex-direction: column; }
          .billing-report__results__table-view__table__popup-details__title {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            margin-bottom: 12px; }
          .billing-report__results__table-view__table__popup-details__invoice-data {
            display: flex;
            flex-direction: column; }
            .billing-report__results__table-view__table__popup-details__invoice-data__invoice {
              display: flex;
              justify-content: space-between; }
              .billing-report__results__table-view__table__popup-details__invoice-data__invoice__title {
                margin-right: 12px;
                font-weight: bold; }

.new-brochure-modal {
  width: 50% !important; }
  .new-brochure-modal__content {
    display: flex !important;
    flex-direction: column;
    grid-gap: 16px;
    gap: 16px; }
    .new-brochure-modal__content__name {
      width: 100%; }
    .new-brochure-modal__content__file {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: center; }
    .new-brochure-modal__content__preview {
      display: flex;
      justify-content: center;
      height: 700px; }

.brochures_archive_modal {
  max-width: 80%; }

.brochure-actions {
  display: flex;
  align-items: center; }
  .brochure-actions .ui.selection.dropdown {
    padding: 4px;
    min-height: 0;
    min-height: initial;
    min-width: 200px; }
    .brochure-actions .ui.selection.dropdown .text {
      font-size: 16px; }
    .brochure-actions .ui.selection.dropdown .dropdown.icon {
      padding: 4px; }

.review-certification {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  grid-gap: 128px;
  gap: 128px;
  padding-top: 32px; }
  .review-certification__menu {
    display: flex;
    flex-direction: column;
    grid-gap: 32px;
    gap: 32px;
    margin: 0 0 8px 8px; }
    .review-certification__menu > .header {
      padding-left: 16px; }
  .review-certification__content {
    display: flex;
    flex-direction: column;
    flex: 1 1;
    padding-right: 128px; }
  .review-certification__add-new-modal .modal-form__body {
    min-height: 360px; }

.template-actions {
  display: flex;
  align-items: center; }
  .template-actions .ui.selection.dropdown {
    padding: 4px;
    min-height: 0;
    min-height: initial;
    min-width: 200px; }
    .template-actions .ui.selection.dropdown .text {
      font-size: 16px; }
    .template-actions .ui.selection.dropdown .dropdown.icon {
      padding: 4px; }

.modal-table {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  z-index: 1000; }
  .modal-table__body {
    background-color: var(--bg-main);
    color: var(--modal-form-color);
    padding: 16px;
    position: absolute;
    max-height: 95%;
    overflow: auto; }
    @media (max-width: 568px) {
      .modal-table__body {
        overflow: auto;
        border-radius: 5px 5px 0 0;
        bottom: 0;
        width: 100%; } }
    @media (min-width: 768px) {
      .modal-table__body {
        border-radius: 5px;
        width: 1200px;
        max-width: 90%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%); } }
    .modal-table__body .overflow {
      float: left;
      width: 350px;
      overflow: hidden;
      -ms-text-overflow: ellipsis;
      text-overflow: ellipsis;
      white-space: nowrap; }
    .modal-table__body .overflow:hover {
      overflow: visible; }
    .modal-table__body .overflow:hover span {
      position: relative;
      width: 350px;
      background-color: white;
      right: 0;
      box-shadow: 0 0 4px 0 black;
      border-radius: 1px;
      text-wrap: wrap; }

.ui.button.modal-table__button {
  color: white;
  background-color: var(--modal-form-color); }

.ui.table.qa-ticket-table thead th,
.ui.table.qa-ticket-table tbody td {
  overflow: hidden; }

.sk-activity-title {
  margin: 64px 0 0;
  text-align: center;
  color: #00b5ad; }

.sk-agent-availability {
  margin-top: 32px;
  display: grid;
  grid-template-columns: 1fr 200px;
  color: var(--syntax-main); }
  .sk-agent-availability__report {
    padding: 16px; }
  .sk-agent-availability__calendar-filter .ui.vertical.menu {
    background-color: var(--bg-frame); }

.sk-active-timespan:hover .sk-timespan-preview {
  opacity: 1;
  border-radius: 4px;
  min-width: 300px;
  height: 240px;
  padding: 24px;
  transform: translate3d(-50%, -95px, 0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25); }

.sk-active-timespan.offline + .sk-active-timespan.offline::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: -0.5px;
  background-color: white; }

.sk-active-timespan.available + .sk-active-timespan.available::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: -0.5px;
  background-color: #919191; }

.sk-active-timespan.unavailable + .sk-active-timespan.unavailable::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: -0.5px;
  background-color: #919191; }

.sk-active-timespan .sk-timespan-preview {
  z-index: 101;
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  color: white;
  justify-content: center;
  min-width: 0;
  border-radius: 0;
  opacity: 0;
  padding: 0;
  height: 50px;
  width: 100%;
  overflow: hidden;
  transform: translate3d(-50%, 0, 0);
  transition: padding 400ms, transform 400ms ease-out, height 400ms ease-out, min-width 400ms ease-out, border-radius 400ms, opacity 400ms; }

.sk-difference {
  padding-left: 32px; }

.sk-activity-bar-container {
  margin: 32px 0 64px; }

.sk-activity-tip {
  margin: 16px 0;
  display: block;
  font-style: italic;
  color: #212121;
  color: var(--syntax-main); }

.sk-activity-bar {
  display: flex;
  border-radius: 4px;
  border: solid 2px #212121; }

.sk-active-timespan {
  height: 50px;
  position: relative; }

.sk-activity-container {
  max-width: 1400px;
  width: 100%;
  align-self: center; }

.sk-availability-dates {
  display: flex;
  justify-content: flex-end;
  margin-top: 32px; }
  .sk-availability-dates span {
    color: var(--syntax-main); }

.sk-availability-dates > div:first-child {
  margin-right: 12px; }

.sk-pie-legend {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 13px; }

.sk-pie-legend .sk-difference {
  padding-left: 32px; }

.sk-no-reports {
  color: var(--syntax-main);
  text-align: center;
  margin-top: 48px;
  font-size: 16px;
  font-weight: 600; }

.sk-activity-legend {
  display: flex;
  margin-top: 16px; }

.sk-activity-color {
  display: flex;
  align-items: center;
  color: var(--syntax-main); }

.sk-activity-color:not(:first-child) {
  margin-left: 16px; }

.sk-activity-color > span {
  display: block;
  width: 24px;
  height: 24px;
  border: solid 1px #aeaeae50;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-right: 8px; }

.dx-visibility-change-handler {
  padding-top: 32px; }

.dxc-legend {
  transform: translateY(calc(50% - 34px)); }

.dcx-series-group,
.dxc-labels-group {
  transform: translateX(-4px); }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.team-availability {
  position: relative;
  display: grid;
  grid-template-columns: 1fr 200px;
  color: var(--syntax-main); }
  .team-availability .ui.vertical.menu {
    background: var(--bg-frame); }
  .team-availability__reports {
    padding: 16px; }
    .team-availability__reports > h2,
    .team-availability__reports h4 {
      text-align: center; }
    .team-availability__reports > h2 {
      color: var(--team-availability-title); }

.shift-display {
  height: 50px;
  display: flex;
  margin: 16px 0; }

.user-profile-container {
  display: flex;
  flex-direction: column; }
  .user-profile-container .user-profile-header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 4em;
    background-color: #00b5ad;
    color: white;
    font-size: 16px; }
    .user-profile-container .user-profile-header .user-profile-header-email {
      margin-right: 0.5em; }
    .user-profile-container .user-profile-header .profileImage {
      border-radius: 50%;
      width: 50px;
      height: 50px;
      margin-right: 1em;
      background-color: #0ce6db;
      display: flex;
      align-items: center;
      justify-content: center; }
  .user-profile-container .user-profile-content h2 {
    color: #08aaaa;
    display: flex;
    justify-content: center; }
  .user-profile-container .user-profile-content h4 {
    color: var(--syntax-main);
    margin-left: 0.5em; }
  .user-profile-container .user-profile-content .user-profile-content-permissions {
    border: 1px solid gray;
    background-color: var(--bg-main);
    display: flex;
    justify-content: space-evenly;
    flex-direction: column;
    width: 40%;
    padding: 1.5em 1em 0em 1em; }
    .user-profile-container .user-profile-content .user-profile-content-permissions .permission {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      color: var(--syntax-main); }
    .user-profile-container .user-profile-content .user-profile-content-permissions p {
      margin-bottom: 2em;
      margin-top: 0; }
  .user-profile-container .user-profile-apply-changes-button {
    display: flex;
    justify-content: flex-end; }

.users-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-color: var(--border-theme);
  border-style: solid;
  border-width: 1px 1px 0 1px; }
  .users-header .users-filters {
    display: flex;
    justify-content: left;
    flex-flow: row wrap;
    grid-gap: 10px;
    gap: 10px; }

.ui.table.user-table {
  border-radius: 0; }
  .ui.table.user-table .availability-table-cell {
    overflow: unset; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.sk-phone-book-modal {
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Sit on top */
  padding-top: 100px;
  /* Location of the box */
  left: 0;
  top: 0;
  height: 100%;
  /* Full height */
  background-color: black;
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
  display: contents; }
  .sk-phone-book-modal .phone-book-container {
    width: 350px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: auto;
    background-color: var(--bg-form-content);
    color: var(--syntax-main); }
    .sk-phone-book-modal .phone-book-container .phone-book-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 0 1 50px;
      background-color: var(--bg-segment-color);
      padding-bottom: 5px; }
      .sk-phone-book-modal .phone-book-container .phone-book-header input {
        align-self: center;
        background: #fff;
        border: 0;
        border-bottom: 1px solid #37598a;
        border-radius: 80px;
        float: right;
        margin: 0;
        width: 40px;
        padding: 0 11px;
        height: 40px;
        outline: none;
        color: #fff;
        font-size: 11px;
        line-height: 22px;
        text-indent: 300px; }
      .sk-phone-book-modal .phone-book-container .phone-book-header input:focus {
        color: #858585;
        text-align: left;
        text-indent: 0;
        padding: 0 11px 0 22px;
        width: 220px; }
        .sk-phone-book-modal .phone-book-container .phone-book-header input:focus + * {
          display: none; }
      .sk-phone-book-modal .phone-book-container .phone-book-header p {
        font-size: 20px;
        color: white;
        align-self: center;
        margin-left: 0.3em; }
    .sk-phone-book-modal .phone-book-container .phone::before {
      transform: none;
      top: 0;
      left: 0;
      width: 0;
      position: relative; }
    .sk-phone-book-modal .phone-book-container .phone-book-content {
      display: flex;
      flex: 0 1 500px;
      max-width: 500px;
      overflow-y: scroll; }
      .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list {
        display: flex;
        flex-direction: column;
        padding: 0 0 435px 0;
        flex: 0 1 100%;
        overflow-y: scroll;
        -ms-overflow-style: none; }
        .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list .phone-book-list-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          padding-bottom: 0.4em;
          padding-top: 0.4em; }
          .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list .phone-book-list-item.main {
            background-color: #b5ffff; }
          .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list .phone-book-list-item:hover {
            background-color: #b5f5f2; }
          .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list .phone-book-list-item .item {
            display: flex;
            flex: 0 0 65%;
            margin-left: 5px; }
          .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list .phone-book-list-item .phone-book-list-item-button-group {
            flex: 0 0 35%; }
            .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list .phone-book-list-item .phone-book-list-item-button-group button {
              margin-left: 5px; }
            .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list .phone-book-list-item .phone-book-list-item-button-group > .sk-call-button {
              border-radius: 50% !important;
              height: 50px !important;
              width: 50px !important; }
        .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list hr {
          width: 100%;
          background-color: #e21313; }
      .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-list::-webkit-scrollbar {
        display: none; }
      .sk-phone-book-modal .phone-book-container .phone-book-content .phone-book-sidebar {
        display: flex;
        flex-direction: column;
        flex: 0 1 10%;
        align-items: flex-end; }
    .sk-phone-book-modal .phone-book-container .phone-book-content::-webkit-scrollbar {
      display: none; }
    .sk-phone-book-modal .phone-book-container .phone-book-add-contact {
      display: flex;
      background-color: #e6e6e6;
      flex: 0 1 50px;
      flex-direction: column; }
      .sk-phone-book-modal .phone-book-container .phone-book-add-contact .add-contact-button {
        background-color: var(--bg-segment-color);
        border-radius: 0;
        color: white;
        margin: 0;
        height: 100%; }
      .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact {
        background-color: var(--bg-form-content);
        color: var(--syntax-main);
        height: 600px; }
        .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-input-contact {
          display: flex;
          margin: 5px; }
          .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-input-contact > div {
            flex: 0 1 30%; }
          .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-input-contact > input {
            flex: 0 1 70%; }
        .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-input-number {
          display: flex;
          margin: 5px; }
          .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-input-number > div {
            flex: 0 1 30%; }
          .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-input-number > input {
            flex: 0 1 70%;
            align-self: center; }
        .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-button-group {
          display: flex;
          justify-content: space-evenly; }
        .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-button,
        .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-exit-button {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          margin: 5px; }
        .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-button {
          background-color: #00b5ad;
          color: white; }
        .sk-phone-book-modal .phone-book-container .phone-book-add-contact .form-add-contact .form-add-exit-button {
          background-color: #db2828;
          color: white; }

.phone-book-contact-handlers {
  display: flex;
  flex-direction: row; }
  .phone-book-contact-handlers .phone-book-contact-update-button button,
  .phone-book-contact-handlers .phone-book-contact-set-default button,
  .phone-book-contact-handlers .phone-book-contact-delete-button button {
    background-color: gray;
    border-radius: 50%;
    height: 45px;
    width: 45px;
    border: none;
    cursor: pointer;
    outline: none; }
    .phone-book-contact-handlers .phone-book-contact-update-button button > i,
    .phone-book-contact-handlers .phone-book-contact-set-default button > i,
    .phone-book-contact-handlers .phone-book-contact-delete-button button > i {
      color: white;
      margin: 0; }
    .phone-book-contact-handlers .phone-book-contact-update-button button:hover,
    .phone-book-contact-handlers .phone-book-contact-set-default button:hover,
    .phone-book-contact-handlers .phone-book-contact-delete-button button:hover {
      box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19); }
  .phone-book-contact-handlers .phone-book-contact-update-button {
    margin-right: 5px; }
    .phone-book-contact-handlers .phone-book-contact-update-button button {
      background-color: #67d867; }
  .phone-book-contact-handlers .phone-book-contact-delete-button {
    margin-right: 5px; }
    .phone-book-contact-handlers .phone-book-contact-delete-button button {
      background-color: #ff6961; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.segment-toggle {
  cursor: pointer;
  background-color: var(--bg-table-segment-color) !important; }
  .segment-toggle--open {
    background-color: var(--bg-table-segment-open-color) !important; }

.secretContainer {
  display: flex;
  align-items: baseline; }
  .secretContainer button {
    margin-left: 2rem !important; }

.error {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important; }
  .error__message {
    display: inline-block;
    width: 450px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    transition: all .2s linear; }
  .error__date {
    display: flex;
    grid-gap: 10px;
    gap: 10px;
    padding: 0 !important;
    align-items: center; }

.applicant-emails {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 0 !important; }

.email__subject {
  display: inline-block;
  width: 450px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  transition: all .2s linear; }

.email__subject:focus:after, .email__subject:hover:after {
  content: attr(data-text);
  overflow: visible;
  text-overflow: inherit;
  background-color: var(--bg-form-content);
  position: absolute;
  top: auto;
  width: auto;
  max-width: 30rem;
  border: 1px solid #eaebec;
  padding: 0 .5rem;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.28);
  white-space: normal;
  word-wrap: break-word;
  display: block;
  margin-top: -1.25rem; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.ui.fixed.table td,
.ui.fixed.table th {
  overflow: unset; }

td > div {
  width: 100%; }
  td > div.field > .ui.input {
    width: 100%; }

.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 4rem;
  z-index: 100; }

.sticky-steps {
  position: -webkit-sticky;
  position: sticky;
  top: 8rem;
  z-index: 100; }

.applicant-view-container .ui.header {
  margin: 0; }

.applicant-view-container > .custom-header {
  display: flex;
  justify-content: space-between;
  padding: 12px; }
  .applicant-view-container > .custom-header > div {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 4px;
    gap: 4px; }
    .applicant-view-container > .custom-header > div > * {
      margin: 0 !important; }

.applicant-view-container > .application-commons-container {
  display: flex;
  justify-content: space-between;
  padding: 12px; }
  .applicant-view-container > .application-commons-container > div {
    display: flex;
    flex-wrap: wrap;
    grid-gap: 4px;
    gap: 4px; }
    .applicant-view-container > .application-commons-container > div > button {
      margin: 0 !important; }

.applicant-view-container > .application-icons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding: 12px; }

.applicant-view-container > .application-icons-container > button {
  margin-bottom: 4px; }

.applicant-view-container .document-menu-buttons {
  display: flex;
  justify-content: space-between; }

.applicant-view-container .edit-errors-containter {
  display: flex;
  margin-left: 12px; }

.table-header-links {
  margin-top: 12px;
  display: flex;
  flex-wrap: wrap;
  grid-gap: 12px;
  gap: 12px; }

.table-link-button {
  margin: 0 !important;
  padding: 0 !important;
  font-size: 12px;
  color: aqua;
  background-color: transparent;
  border: none;
  cursor: pointer; }

.sticky-bottom {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  z-index: 900; }

.buttons-container {
  display: flex;
  justify-content: flex-end; }

.zendesk-icon {
  width: 36px;
  height: 36px; }

.error-list {
  display: flex;
  flex-direction: column;
  padding: 8px;
  color: red; }
  .error-list > span {
    color: red; }

.change-type-modal .ui.button {
  margin-top: 16px; }

.group-pack-status {
  margin-left: 8px; }

.appn-timeline-pinned-notes {
  width: 98%;
  color: var(--syntax-main);
  border: 1px solid #dadada;
  border-radius: 10px;
  margin: 20px 1%;
  padding: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); }

.appn-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px 10px;
  justify-content: center;
  align-items: center;
  grid-gap: 20px;
  gap: 20px; }

.appn-actions-container {
  width: 550px;
  border: 1px solid #ccc;
  padding: 10px 20px;
  border-radius: 10px;
  color: var(--syntax-main); }

.appn-text {
  text-align: center; }

.appn-text h3 {
  font-size: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 5px;
  gap: 5px; }

.appn-text p {
  font-size: 1rem;
  margin-top: 10px;
  margin-bottom: 10px; }

.appn-cta {
  display: flex;
  justify-content: center;
  align-self: center; }

/* Comment Box */
.appn-comment-box-title {
  width: 100%;
  font-size: 12pt; }

.appn-comment-box-button {
  padding: 7px 12px;
  border-radius: 5px;
  border: none;
  font-size: 10pt;
  color: #ffffff;
  background-color: #2897bc;
  cursor: pointer;
  transition: all 0.3s ease-in-out; }

.appn-comment-box-button:hover {
  background-color: #175469; }

#publish-comment {
  width: 100%;
  height: auto;
  max-height: 300px;
  padding: 10px;
  border: none;
  font-size: 10pt;
  border: none;
  overflow: auto;
  outline: none;
  box-shadow: none;
  resize: none;
  field-sizing: content;
  background-color: var(--bg-form-content);
  color: var(--syntax-main); }

.appn-comment-box .appn-comment-box-actions {
  border: 2px solid #28bcbc;
  padding: 5px 5px;
  border-radius: 10px;
  background-color: var(--bg-form-content); }

.appn-comment-box .appn-comment-box-options {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: end; }

.appn-timeline {
  margin: 10px 0px;
  position: relative;
  z-index: 1; }

.appn-timeline-entry-content {
  width: 100%;
  position: relative; }

.appn-timeline-entry {
  display: flex;
  align-items: start;
  grid-gap: 10px;
  gap: 10px;
  margin: 10px 0px; }
  .appn-timeline-entry .appn-timeline-entry-avatar img {
    width: 30px;
    height: 30px;
    border-radius: 1000px; }
  .appn-timeline-entry .appn-timeline-entry-title {
    display: flex;
    flex-direction: row;
    justify-content: start;
    grid-gap: 5px;
    gap: 5px; }
    .appn-timeline-entry .appn-timeline-entry-title .appn-timeline-entry-time {
      font-size: 8pt;
      color: #8f8f8f; }
    .appn-timeline-entry .appn-timeline-entry-title h3,
    .appn-timeline-entry .appn-timeline-entry-title p,
    .appn-timeline-entry .appn-timeline-entry-title span {
      font-size: 9pt;
      margin: 0px; }
  .appn-timeline-entry .appn-timeline-comment-box {
    border: 1px solid #e0e0e0;
    padding: 10px;
    font-size: 9pt;
    border-radius: 10px; }

.appn-ticket-status-tag {
  background-color: #c5e8f3;
  color: #286275;
  padding: 5px 8px;
  border-radius: 5px; }

.appn-timeline-comment-container {
  display: flex;
  flex-direction: column;
  grid-gap: 4px;
  gap: 4px; }

.appn-line {
  width: 2px;
  height: 100%;
  border: 1px solid #e0e0e0;
  position: absolute;
  left: 20px;
  top: 0;
  z-index: -1; }

.appn-timeline-entry-pin {
  cursor: pointer;
  position: absolute;
  top: 0;
  right: 0; }

.appn-timeline-pinned-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }

.appn-timeline-pinned-title {
  display: flex;
  align-items: center; }

.appn-timeline-pinned-button {
  background-color: transparent;
  border: none;
  cursor: pointer; }

.embassy-modal-form .ui.checkbox {
  padding: 8px; }
  .embassy-modal-form .ui.checkbox label {
    color: var(--modal-form-color) !important; }

.embassy-modal-form .ui.button {
  margin-top: 16px; }

.main-grid-login {
  background-color: var(--bg-main);
  height: 100vh; }
  .main-grid-login .ui.column {
    max-width: 450px; }
  .main-grid-login .ui.message {
    font-size: 12px; }
  .main-grid-login .login-button {
    height: 36px;
    position: relative;
    padding: 0 !important; }
  .main-grid-login .login-spinner {
    margin: auto; }
  .main-grid-login .ui.grid.login-form {
    margin-top: -50px;
    height: 100%; }
  .main-grid-login.ui.grid {
    margin: 0; }
  .main-grid-login__forgot-password {
    display: flex;
    justify-content: flex-end;
    text-decoration: underline;
    margin: 0 0 1em; }
  .main-grid-login .large.button {
    margin-bottom: 10px; }
  .main-grid-login .google-btn {
    width: 184px;
    height: 42px;
    background-color: #4285f4;
    border-radius: 2px;
    box-shadow: 0 3px 4px 0 rgba(0, 0, 0, 0.25);
    cursor: pointer;
    margin: 10px auto; }
    .main-grid-login .google-btn .google-icon-wrapper {
      position: absolute;
      margin-top: 1px;
      margin-left: 1px;
      width: 40px;
      height: 40px;
      border-radius: 2px;
      background-color: #fff; }
    .main-grid-login .google-btn .google-icon {
      margin-top: 11px;
      width: 18px;
      height: 18px; }
    .main-grid-login .google-btn .btn-text {
      float: right;
      margin: 11px 11px 0 0;
      color: #fff;
      font-size: 14px;
      letter-spacing: 0.2px;
      font-family: "Roboto"; }
    .main-grid-login .google-btn:hover {
      box-shadow: 0 0 6px #4285f4; }
    .main-grid-login .google-btn:active {
      background: #1669F2; }

/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
/**
  Convert PX to REM based on global font size. | v1.0 | 2016
 */
/**
  Position mixin to help with one line solution.
 */
/**
 This is the order at which the elements will stack.
 List goes from lowest to highest.

 LOWEST -------------------> HIGHEST

 *Example:
 @include z-index('navigation');
 */
.toast-title {
  font-weight: bold; }

.toast-message {
  -ms-word-wrap: break-word;
  word-wrap: break-word; }

.toast-message a,
.toast-message label {
  color: #ffffff; }

.toast-message a:hover {
  color: #cccccc;
  text-decoration: none; }

.toast-close-button {
  position: relative;
  right: -0.3em;
  top: -0.3em;
  float: right;
  font-size: 20px;
  font-weight: bold;
  color: #ffffff;
  -webkit-text-shadow: 0 1px 0 #ffffff;
  text-shadow: 0 1px 0 #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

.toast-close-button:hover,
.toast-close-button:focus {
  color: #000000;
  text-decoration: none;
  cursor: pointer;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Additional properties for button version
 iOS requires the button element instead of an anchor tag.
 If you want the anchor version, it requires `href="#"`.*/
button.toast-close-button {
  padding: 0;
  cursor: pointer;
  background: transparent;
  border: 0;
  -webkit-appearance: none; }

.toast-top-center {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-center {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-full-width {
  top: 0;
  right: 0;
  width: 100%; }

.toast-bottom-full-width {
  bottom: 0;
  right: 0;
  width: 100%; }

.toast-top-left {
  top: 12px;
  left: 12px; }

.toast-top-right {
  top: 12px;
  right: 12px; }

.toast-bottom-right {
  right: 12px;
  bottom: 12px; }

.toast-bottom-left {
  bottom: 12px;
  left: 12px; }

#toast-container {
  position: fixed;
  z-index: 999999;
  /*overrides*/ }

#toast-container * {
  box-sizing: border-box; }

#toast-container > div {
  position: relative;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 15px 15px 15px 50px;
  width: 300px;
  border-radius: 3px 3px 3px 3px;
  background-position: 15px center;
  background-repeat: no-repeat;
  box-shadow: 0 0 12px #999999;
  color: #ffffff;
  opacity: 0.8;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=80);
  filter: alpha(opacity=80); }

#toast-container > div:hover {
  box-shadow: 0 0 12px #000000;
  opacity: 1;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  filter: alpha(opacity=100);
  cursor: pointer; }

#toast-container > .toast-info {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGwSURBVEhLtZa9SgNBEMc9sUxxRcoUKSzSWIhXpFMhhYWFhaBg4yPYiWCXZxBLERsLRS3EQkEfwCKdjWJAwSKCgoKCcudv4O5YLrt7EzgXhiU3/4+b2ckmwVjJSpKkQ6wAi4gwhT+z3wRBcEz0yjSseUTrcRyfsHsXmD0AmbHOC9Ii8VImnuXBPglHpQ5wwSVM7sNnTG7Za4JwDdCjxyAiH3nyA2mtaTJufiDZ5dCaqlItILh1NHatfN5skvjx9Z38m69CgzuXmZgVrPIGE763Jx9qKsRozWYw6xOHdER+nn2KkO+Bb+UV5CBN6WC6QtBgbRVozrahAbmm6HtUsgtPC19tFdxXZYBOfkbmFJ1VaHA1VAHjd0pp70oTZzvR+EVrx2Ygfdsq6eu55BHYR8hlcki+n+kERUFG8BrA0BwjeAv2M8WLQBtcy+SD6fNsmnB3AlBLrgTtVW1c2QN4bVWLATaIS60J2Du5y1TiJgjSBvFVZgTmwCU+dAZFoPxGEEs8nyHC9Bwe2GvEJv2WXZb0vjdyFT4Cxk3e/kIqlOGoVLwwPevpYHT+00T+hWwXDf4AJAOUqWcDhbwAAAAASUVORK5CYII=") !important; }

#toast-container > .toast-error {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAHOSURBVEhLrZa/SgNBEMZzh0WKCClSCKaIYOED+AAKeQQLG8HWztLCImBrYadgIdY+gIKNYkBFSwu7CAoqCgkkoGBI/E28PdbLZmeDLgzZzcx83/zZ2SSXC1j9fr+I1Hq93g2yxH4iwM1vkoBWAdxCmpzTxfkN2RcyZNaHFIkSo10+8kgxkXIURV5HGxTmFuc75B2RfQkpxHG8aAgaAFa0tAHqYFfQ7Iwe2yhODk8+J4C7yAoRTWI3w/4klGRgR4lO7Rpn9+gvMyWp+uxFh8+H+ARlgN1nJuJuQAYvNkEnwGFck18Er4q3egEc/oO+mhLdKgRyhdNFiacC0rlOCbhNVz4H9FnAYgDBvU3QIioZlJFLJtsoHYRDfiZoUyIxqCtRpVlANq0EU4dApjrtgezPFad5S19Wgjkc0hNVnuF4HjVA6C7QrSIbylB+oZe3aHgBsqlNqKYH48jXyJKMuAbiyVJ8KzaB3eRc0pg9VwQ4niFryI68qiOi3AbjwdsfnAtk0bCjTLJKr6mrD9g8iq/S/B81hguOMlQTnVyG40wAcjnmgsCNESDrjme7wfftP4P7SP4N3CJZdvzoNyGq2c/HWOXJGsvVg+RA/k2MC/wN6I2YA2Pt8GkAAAAASUVORK5CYII=") !important; }

#toast-container > .toast-success {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAADsSURBVEhLY2AYBfQMgf///3P8+/evAIgvA/FsIF+BavYDDWMBGroaSMMBiE8VC7AZDrIFaMFnii3AZTjUgsUUWUDA8OdAH6iQbQEhw4HyGsPEcKBXBIC4ARhex4G4BsjmweU1soIFaGg/WtoFZRIZdEvIMhxkCCjXIVsATV6gFGACs4Rsw0EGgIIH3QJYJgHSARQZDrWAB+jawzgs+Q2UO49D7jnRSRGoEFRILcdmEMWGI0cm0JJ2QpYA1RDvcmzJEWhABhD/pqrL0S0CWuABKgnRki9lLseS7g2AlqwHWQSKH4oKLrILpRGhEQCw2LiRUIa4lwAAAABJRU5ErkJggg==") !important; }

#toast-container > .toast-warning {
  background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAGYSURBVEhL5ZSvTsNQFMbXZGICMYGYmJhAQIJAICYQPAACiSDB8AiICQQJT4CqQEwgJvYASAQCiZiYmJhAIBATCARJy+9rTsldd8sKu1M0+dLb057v6/lbq/2rK0mS/TRNj9cWNAKPYIJII7gIxCcQ51cvqID+GIEX8ASG4B1bK5gIZFeQfoJdEXOfgX4QAQg7kH2A65yQ87lyxb27sggkAzAuFhbbg1K2kgCkB1bVwyIR9m2L7PRPIhDUIXgGtyKw575yz3lTNs6X4JXnjV+LKM/m3MydnTbtOKIjtz6VhCBq4vSm3ncdrD2lk0VgUXSVKjVDJXJzijW1RQdsU7F77He8u68koNZTz8Oz5yGa6J3H3lZ0xYgXBK2QymlWWA+RWnYhskLBv2vmE+hBMCtbA7KX5drWyRT/2JsqZ2IvfB9Y4bWDNMFbJRFmC9E74SoS0CqulwjkC0+5bpcV1CZ8NMej4pjy0U+doDQsGyo1hzVJttIjhQ7GnBtRFN1UarUlH8F3xict+HY07rEzoUGPlWcjRFRr4/gChZgc3ZL2d8oAAAAASUVORK5CYII=") !important; }

#toast-container.toast-top-center > div,
#toast-container.toast-bottom-center > div {
  width: 300px;
  margin-left: auto;
  margin-right: auto; }

#toast-container.toast-top-full-width > div,
#toast-container.toast-bottom-full-width > div {
  width: 96%;
  margin-left: auto;
  margin-right: auto; }

.toast {
  background-color: #030303; }

.toast-success {
  background-color: #51a351; }

.toast-error {
  background-color: #bd362f; }

.toast-info {
  background-color: #2f96b4; }

.toast-warning {
  background-color: #f89406; }

.toast-progress {
  position: absolute;
  left: 0;
  bottom: 0;
  height: 4px;
  background-color: #000000;
  opacity: 0.4;
  -ms-filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=40);
  filter: alpha(opacity=40); }

/*Responsive Design*/
@media all and (max-width: 240px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 11em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 241px) and (max-width: 480px) {
  #toast-container > div {
    padding: 8px 8px 8px 50px;
    width: 18em; }
  #toast-container .toast-close-button {
    right: -0.2em;
    top: -0.2em; } }

@media all and (min-width: 481px) and (max-width: 768px) {
  #toast-container > div {
    padding: 15px 15px 15px 50px;
    width: 25em; } }

body ::-webkit-scrollbar {
  width: 5px; }

:root {
  --top-menu-height: 4em; }

:root.dark {
  --bg-frame: #212122;
  --bg-form-frame: #212122;
  --bg-form-content: #595959;
  --bg-main: #1a1a1e;
  --bg-table-thead: #172224;
  --bg-table-tfood: #172224;
  --bg-table-tr-odd: #202020;
  --bg-table-tr-hover: #595959;
  --bg-segment-color: #212122;
  --bg-tabular-active-color: white;
  --bg-table-colspan-color: inherit;
  --bg-table-segment-color: #0b2b51;
  --bg-table-segment-open-color: #018605;
  --border-theme: #4a4a4f;
  --bg-general-report: #424242;
  --bg-react-calendar: #212122;
  --bg-dropdown: black;
  --bg-dropdown-hover: #1a1a1e;
  --bg-dropdown-selected-item: #262626;
  --bg-empty-shift: #212122;
  --syntax-table-th: #61ccee;
  --syntax-table-tr: #e3e3e3;
  --syntax-main: white;
  --syntax-main-inverse: black;
  --color-link: #08d7ce;
  --team-availability-title: #6fb4c8;
  --sortable-th: #6fb4c8;
  --modal-form-color: #6fb4c8; }

:root.light {
  --bg-frame: #343437;
  --bg-form-frame: #e3e3e3;
  --bg-form-content: white;
  --bg-main: white;
  --bg-table-thead: #e7fde5;
  --bg-table-tfood: white;
  --bg-table-tr-odd: #edf6fb;
  --bg-table-tr-hover: #d5eaf6;
  --bg-segment-color: #0b2b51;
  --bg-tabular-active-color: #0b2b51;
  --bg-table-colspan-color: #0b2b51;
  --bg-table-segment-color: #0b2b51;
  --bg-table-segment-open-color: #018605;
  --border-theme: rgba(34, 36, 38, 0.15);
  --bg-general-report: #f0f0f0;
  --bg-react-calendar: #57575c;
  --bg-dropdown: white;
  --bg-dropdown-hover: #cccccc;
  --bg-dropdown-selected-item: #f0f0f0;
  --bg-empty-shift: white;
  --syntax-table-th: black;
  --syntax-table-tr: black;
  --syntax-main: black;
  --syntax-main-inverse: white;
  --color-link: #a602b3;
  --team-availability-title: #00b5ad;
  --sortable-th: #bdfab8;
  --modal-form-color: #0b2b51; }

:root.zendesk {
  --bg-frame: #343437;
  --bg-form-frame: #e3e3e3;
  --bg-form-content: white;
  --bg-main: white;
  --bg-table-thead: #e7fde5;
  --bg-table-tfood: white;
  --bg-table-tr-odd: #DAEDED;
  --bg-table-tr-hover: #d5eaf6;
  --bg-segment-color: #03363D;
  --bg-tabular-active-color: #0b2b51;
  --bg-table-colspan-color: #0b2b51;
  --bg-table-segment-color: #17494d;
  --bg-table-segment-open-color: #018605;
  --border-theme: rgba(34, 36, 38, 0.15);
  --bg-general-report: #f0f0f0;
  --bg-react-calendar: #57575c;
  --bg-dropdown: white;
  --bg-dropdown-hover: #cccccc;
  --bg-dropdown-selected-item: #f0f0f0;
  --bg-empty-shift: white;
  --syntax-table-th: black;
  --syntax-table-tr: black;
  --syntax-main: black;
  --syntax-main-inverse: white;
  --color-link: #a602b3;
  --team-availability-title: #00b5ad;
  --sortable-th: #bdfab8;
  --modal-form-color: #03363D; }

:root .ui.primary.button, :root .ui.blue.button {
  background-color: #17494d; }

:root .sticky-bottom .ui.primary.button, :root .sticky-bottom .ui.blue.button, :root .zendesk-top-menu .ui.primary.button, :root .zendesk-top-menu .ui.blue.button {
  background-color: #DAEDED;
  color: black; }

.grid {
  width: 100%;
  display: grid;
  grid: 4em/1fr;
  grid: var(--top-menu-height)/1fr;
  grid-template-areas: "menu" "maincontent"; }

.menu {
  grid-area: menu;
  z-index: 100; }

.sk-small-content {
  padding: 10px;
  width: calc(100% - 4em); }

.drop-left-padding {
  padding-left: 0 !important; }

.label-on-corner {
  top: 0.8em !important;
  left: 78% !important; }

.loader .ui.segment {
  height: 600px; }

.ui.button.save-button {
  background-color: #00b5ad;
  color: white; }

.ui.button.discard-button {
  background-color: #cacaca;
  color: #666666; }

.tableTopSegment {
  padding: 12px !important;
  display: flex;
  flex-direction: column; }

.ui.header.tableTitle {
  margin: 0;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between; }

.ui.tableTitle .filters-container .ui.checkbox label {
  color: #ff7e7e;
  font-weight: bold; }

html,
.pushable {
  background: var(--bg-main); }

.ui.header.tableTitle > h3 {
  margin: 0; }

.ui table {
  margin: 0 !important; }

.filters-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly; }
  @media (min-width: 768px) {
    .filters-container > div {
      margin-right: 10px; } }
  @media (max-width: 568px) {
    .filters-container {
      display: grid; }
      .filters-container > * {
        margin: 0 0 8px 0; } }

.adminTable {
  display: flex;
  justify-content: space-between; }

.chart-container {
  margin-bottom: -32px; }

.chart-legend {
  display: none; }

.sk-textarea {
  width: 100%;
  min-height: 150px;
  text-align: left;
  font-family: Lato, "Helvetica Neue", Arial, Helvetica, sans-serif;
  padding: 0.67857143em 1em;
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: rgba(0, 0, 0, 0.87);
  border-radius: 0.28571429rem;
  resize: vertical; }

#root {
  height: 100%; }

.sk-modal-form-container {
  background-color: var(--bg-form-content);
  color: var(--syntax-main);
  display: flex;
  flex-direction: column;
  padding: 20px 30px 20px 30px; }
  .sk-modal-form-container > .sk-modal-form-item {
    margin: 12px;
    display: flex; }
    .sk-modal-form-container > .sk-modal-form-item > .sk-modal-form-item-label {
      flex: 1 1; }
    .sk-modal-form-container > .sk-modal-form-item > .sk-modal-form-item-field {
      flex: 3 1; }
      .sk-modal-form-container > .sk-modal-form-item > .sk-modal-form-item-field > * {
        width: 100%; }

.sk-table-filters {
  display: flex;
  justify-content: flex-end;
  margin: 20px; }
  .sk-table-filters > * {
    margin-right: 10px; }
  .sk-table-filters__brochures {
    display: flex;
    justify-content: flex-end;
    margin: 10px; }

.sk-table-filters-export-tool {
  display: flex;
  flex-direction: column;
  margin: 20px;
  grid-gap: 10px;
  gap: 10px; }
  .sk-table-filters-export-tool__filters {
    display: flex;
    justify-content: flex-end;
    grid-gap: 10px;
    gap: 10px; }
  .sk-table-filters-export-tool__sections {
    display: flex;
    justify-content: flex-end; }

.sk-table-header {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.sk-table-segment {
  padding: 10px; }

.animate-on-height {
  transition: height 150ms ease-in-out; }

.ui.dropdown button + .menu {
  margin-top: 2px; }

.react-datetime-picker__wrapper {
  border: 1px solid rgba(34, 36, 38, 0.15);
  color: var(--syntax-main);
  border-radius: 0.28571429rem;
  flex-grow: unset; }

.react-datetime-picker__inputGroup,
.react-datetime-picker * {
  color: var(--syntax-main); }

.react-calendar {
  background-color: var(--bg-main); }
  .react-calendar__tile:enabled:hover {
    background-color: gray; }
  .react-calendar__tile--now abbr {
    color: black; }

.overlay {
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 119; }

@media only screen and (max-width: 1275px) {
  .action-buttons {
    display: flex;
    flex-direction: column; }
  .action-buttons button {
    width: 30.25px; } }

.ui.menu.top-menu.top-menu--simulation,
.ui.menu.top-menu {
  background: #1a1a1e;
  border-bottom: 1px solid #2077e1; }
  .ui.menu.top-menu.top-menu--simulation .item.top-menu__no-border .ui.checkbox label,
  .ui.menu.top-menu.top-menu--simulation .top-menu__header-right__logged-as,
  .ui.menu.top-menu.top-menu--simulation a,
  .ui.menu.top-menu .item.top-menu__no-border .ui.checkbox label,
  .ui.menu.top-menu .top-menu__header-right__logged-as,
  .ui.menu.top-menu a {
    color: #6fb4c8; }

.ui.slider.checkbox label:before {
  background-color: gray; }

@media (min-width: 768px) {
  .ui.selection.dropdown {
    margin-right: 10px; } }

.ui.celled.table tr td:first-child,
.ui.celled.table tr th:first-child {
  font-weight: bold; }

.ui.table thead th,
.ui.sortable.table thead th {
  font-size: 16px;
  white-space: nowrap; }

.ui.pagination.menu {
  background-color: var(--bg-frame);
  border-bottom: 1px solid #2077e1; }
  .ui.pagination.menu > a {
    font-weight: bold; }
  .ui.pagination.menu .item {
    color: #6fb4c8; }
    .ui.pagination.menu .item:hover {
      color: white; }
  .ui.pagination.menu .active.item {
    color: white; }

.ui.attached.tabular .item {
  font-weight: bold;
  color: #6fb4c8; }

.sk-chat-participants h1 {
  color: #6fb4c8; }

.ui.vertical.menu .active.item,
.ui.vertical.menu .active.item:hover,
.ui.vertical.menu .main-container__sidemenu__bottom .item:hover,
.ui.vertical.menu .main-container__sidemenu__bottom .active.item,
.ui.vertical.menu .item:hover .content .item:hover {
  color: #6fb4c8;
  font-weight: bold; }
  .ui.vertical.menu .active.item .icon,
  .ui.vertical.menu .active.item:hover .icon,
  .ui.vertical.menu .main-container__sidemenu__bottom .item:hover .icon,
  .ui.vertical.menu .main-container__sidemenu__bottom .active.item .icon,
  .ui.vertical.menu .item:hover .content .item:hover .icon {
    color: #6fb4c8; }
  .ui.vertical.menu .active.item .text-icon,
  .ui.vertical.menu .active.item:hover .text-icon,
  .ui.vertical.menu .main-container__sidemenu__bottom .item:hover .text-icon,
  .ui.vertical.menu .main-container__sidemenu__bottom .active.item .text-icon,
  .ui.vertical.menu .item:hover .content .item:hover .text-icon {
    color: white; }

.ui.accordion.menu .item,
.ui.accordion.menu .item .title,
.ui.accordion.menu .item .content .ui.vertical.menu .item {
  color: #6fb4c8;
  font-weight: bold; }

.ui.vertical.menu {
  background: var(--border-theme); }

.ui.segment {
  background: var(--bg-main); }

.sk-session-item,
.ui.segments:not(.horizontal) > .segment:last-child {
  background: var(--bg-main);
  color: var(--syntax-main); }

.ui.table,
.ui.sortable.table,
.ui.selectable.table {
  background: var(--bg-main);
  color: var(--syntax-main);
  border: 1px solid var(--border-theme); }
  .ui.table thead th,
  .ui.table thead:not(.full-width) tr th:first-child,
  .ui.sortable.table thead th,
  .ui.sortable.table thead:not(.full-width) tr th:first-child,
  .ui.selectable.table thead th,
  .ui.selectable.table thead:not(.full-width) tr th:first-child {
    background-color: var(--bg-table-thead);
    color: var(--syntax-table-th); }
  .ui.table thead tr .ui.checkbox label,
  .ui.sortable.table thead tr .ui.checkbox label,
  .ui.selectable.table thead tr .ui.checkbox label {
    color: var(--syntax-main); }
  .ui.table tbody tr:hover, .ui.table tbody tr.active,
  .ui.sortable.table tbody tr:hover,
  .ui.sortable.table tbody tr.active,
  .ui.selectable.table tbody tr:hover,
  .ui.selectable.table tbody tr.active {
    background-color: var(--bg-table-tr-hover) !important; }
    .ui.table tbody tr:hover td, .ui.table tbody tr.active td,
    .ui.sortable.table tbody tr:hover td,
    .ui.sortable.table tbody tr.active td,
    .ui.selectable.table tbody tr:hover td,
    .ui.selectable.table tbody tr.active td {
      color: var(--syntax-main); }

.ui.definition.table thead:not(.full-width) th:first-child {
  border: hidden;
  font-weight: bold;
  font-size: 20px; }

.ui.definition.table tbody tr td:first-child:not(.ignored) {
  color: var(--syntax-main); }

.ui.table tfoot tr:first-child > th:only-child,
.users-header {
  background: var(--bg-table-tfood); }

.ui.striped.table tbody tr:nth-child(2n),
.ui.striped.table > tr:nth-child(2n) {
  background-color: var(--bg-table-tr-odd);
  color: var(--syntax-main); }

.ui.striped.table {
  background-color: var(--bg-main);
  color: var(--syntax-table-tr);
  border: 1px solid var(--border-theme); }
  .ui.striped.table tbody > tr > td[colspan="2"] > h2 {
    text-align: center;
    background: var(--bg-table-colspan-color);
    color: white;
    border-radius: 4px;
    padding: 4px; }

a {
  text-decoration: none;
  font-weight: bold; }
  a, a:hover {
    color: var(--color-link); }

.ui.inverted.segment {
  background-color: var(--bg-segment-color); }

.ui.selection.dropdown,
.ui.selection.dropdown .menu,
.ui.selection.dropdown .item,
.ui.selection.dropdown .item.active,
.ui.button.dropdown,
.ui.button.dropdown .menu,
.ui.button.dropdown .item,
.ui.button.dropdown .item.active {
  background-color: var(--bg-dropdown); }

.ui.selection.dropdown:hover,
.ui.selection.dropdown .item:hover,
.ui.button.dropdown:hover,
.ui.button.dropdown .item:hover {
  background-color: var(--bg-dropdown-hover); }

.ui.selection.dropdown a.ui.label,
.ui.selection.dropdown .ui.button.dropdown a.ui.label,
.ui.button.dropdown a.ui.label,
.ui.button.dropdown .ui.button.dropdown a.ui.label {
  background-color: var(--bg-dropdown-selected-item); }

.ui.selection.dropdown * {
  color: var(--syntax-main); }

.ui.selection.dropdown *.active {
  color: var(#6fb4c8); }

.ui.button.dropdown .menu .item {
  background-color: var(--bg-dropdown); }

.ui.input input,
.ui.input input[type="text"],
.ui.input input[type="email"],
.ui.input input[type="date"],
.ui.input input[type="number"],
.ui.input input[type="password"],
.ui.form textarea,
.ui.form .ui.message,
textarea.sk-textarea,
textarea.qa-questions-item {
  background-color: var(--bg-dropdown);
  color: var(--syntax-main); }

::-webkit-scrollbar-track {
  background-color: #0b2b51 !important; }

::-webkit-scrollbar-thumb {
  background-color: #61ccee !important; }

* {
  scrollbar-color: #61ccee #0b2b51;
  scrollbar-width: thin; }

.ui.tabular.menu .active.item {
  background-color: var(--bg-tabular-active-color);
  color: var(--syntax-main-inverse); }

.interviews-table {
  background-color: var(--bg-table-thead) !important; }
  .interviews-table__header {
    flex-direction: row; }
    .interviews-table__header__actions {
      display: flex;
      grid-gap: 10px;
      gap: 10px; }
      .interviews-table__header__actions .ui.button {
        width: 100px; }
  .interviews-table td {
    white-space: pre-line; }

.ui.bottom.attached.segment.active.tab {
  display: flex;
  flex-direction: column;
  grid-gap: 16px;
  gap: 16px; }

.avatar-container {
  width: 40px;
  height: 40px;
  border-radius: 1000px;
  background-color: #1e2281;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  text-transform: capitalize; }

.avatar-container .avatar-text {
  font-size: 16pt; }

