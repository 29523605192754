.sk-activity-legend {
  display: flex;
  margin-top: 16px;
}

.sk-activity-color {
  display: flex;
  align-items: center;
  color: var(--syntax-main);
}

.sk-activity-color:not(:first-child) {
  margin-left: 16px;
}

.sk-activity-color > span {
  display: block;
  width: 24px;
  height: 24px;
  border: solid 1px #aeaeae50;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  margin-right: 8px;
}
