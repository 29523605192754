@import "../../styles/settings.scss";

.sk-phone-book-modal {
  // display: none; /* Hidden by default */
  position: fixed; /* Stay in place */
  z-index: 1; /* Sit on top */
  padding-top: 100px; /* Location of the box */
  left: 0;
  top: 0;
  // width: 100%; /* Full width */
  height: 100%; /* Full height */
  // overflow: auto; /* Enable scroll if needed */
  background-color: rgb(0, 0, 0); /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4); /* Black w/ opacity */
  display: contents; // to position dial pad container in modal
  .phone-book-container {
    width: 350px;
    height: 600px;
    display: flex;
    flex-direction: column;
    align-content: center;
    margin: auto;

    background-color: var(--bg-form-content);
    color: var(--syntax-main);

    .phone-book-header {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      flex: 0 1 50px;
      background-color: var(--bg-segment-color);
      // margin-bottom: 5px;
      padding-bottom: 5px;
      input {
        // display: flex;
        align-self: center;
        // color: white;
        background: #fff;
        border: 0;
        border-bottom: 1px solid #37598a;
        border-radius: 80px;
        float: right;

        margin: 0;
        width: 40px;
        padding: 0 11px;
        height: 40px;
        outline: none;

        color: #fff;
        font-size: 11px;
        line-height: 22px;
        text-indent: 300px;
      }

      input:focus {
        color: #858585;
        text-align: left;
        text-indent: 0;
        padding: 0 11px 0 22px;
        width: 220px;
        + * {
          display: none;
        }
      }
      p {
        font-size: 20px;
        color: white;
        align-self: center;
        margin-left: 0.3em;
      }
    }

    .phone::before {
      transform: none;
      top: 0;
      left: 0;
      width: 0;
      position: relative;
    }

    .phone-book-content {
      display: flex;
      flex: 0 1 500px;
      max-width: 500px;
      overflow-y: scroll;

      .phone-book-list {
        display: flex;
        flex-direction: column;
        padding: 0 0 435px 0;
        flex: 0 1 100%;
        overflow-y: scroll;
        -ms-overflow-style: none;

        .phone-book-list-item {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          // margin-bottom: 0.7em;
          padding-bottom: 0.4em;
          padding-top: 0.4em;
          &.main {
            background-color: #b5ffff;
          }
          &:hover {
            background-color: #b5f5f2;
          }
          .item {
            display: flex;
            flex: 0 0 65%;
            margin-left: 5px;
          }
          .phone-book-list-item-button-group {
            flex: 0 0 35%;
            button {
              margin-left: 5px;
            }
            & > .sk-call-button {
              border-radius: 50% !important;
              height: 50px !important;
              width: 50px !important;
            }
          }
        }
        hr {
          width: 100%;
          background-color: rgb(226, 19, 19);
        }
      }

      .phone-book-list::-webkit-scrollbar {
        display: none;
      }

      .phone-book-sidebar {
        display: flex;
        flex-direction: column;
        flex: 0 1 10%;
        align-items: flex-end;
      }
    }
    .phone-book-content::-webkit-scrollbar {
      display: none;
    }

    .phone-book-add-contact {
      display: flex;
      background-color: rgb(230, 230, 230);
      flex: 0 1 50px;
      flex-direction: column;
      .add-contact-button {
        background-color: var(--bg-segment-color);
        border-radius: 0;
        color: white;
        margin: 0;
        height: 100%;
      }

      .form-add-contact {
        background-color: var(--bg-form-content);
        color: var(--syntax-main);
        height: 600px;
        .form-add-input-contact {
          display: flex;
          margin: 5px;
          > div {
            flex: 0 1 30%;
          }
          > input {
            flex: 0 1 70%;
          }
        }
        .form-add-input-number {
          display: flex;
          margin: 5px;
          > div {
            flex: 0 1 30%;
          }
          > input {
            flex: 0 1 70%;
            align-self: center;
          }
        }
        .form-add-button-group {
          display: flex;
          justify-content: space-evenly;
        }

        .form-add-button,
        .form-add-exit-button {
          border-radius: 50%;
          width: 50px;
          height: 50px;
          display: flex;
          justify-content: center;
          margin: 5px;
        }
        .form-add-button {
          background-color: #00b5ad;
          color: white;
        }
        .form-add-exit-button {
          background-color: #db2828;
          color: white;
        }
      }
    }
  }
}

.phone-book-contact-handlers {
  display: flex;
  flex-direction: row;

  .phone-book-contact-update-button,
  .phone-book-contact-set-default,
  .phone-book-contact-delete-button {
    button {
      background-color: $color-grey;
      border-radius: 50%;
      height: 45px;
      width: 45px;
      border: none;

      cursor: pointer;
      outline: none;
      > i {
        color: white;
        margin: 0;
      }
      &:hover {
        box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24),
          0 17px 50px 0 rgba(0, 0, 0, 0.19);
      }
    }
  }

  .phone-book-contact-update-button {
    margin-right: 5px;
    button {
      background-color: #67d867;
    }
  }
  .phone-book-contact-delete-button {
    margin-right: 5px;
    button {
      background-color: #ff6961;
    }
  }
}
