.sk-active-timespan:hover .sk-timespan-preview {
  opacity: 1;
  border-radius: 4px;
  min-width: 300px;
  height: 240px;
  padding: 24px;
  transform: translate3d(-50%, -95px, 0);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.25);
}

.sk-active-timespan.offline + .sk-active-timespan.offline::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: -0.5px;
  background-color: white;
}

.sk-active-timespan.available + .sk-active-timespan.available::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: -0.5px;
  background-color: #919191;
}

.sk-active-timespan.unavailable + .sk-active-timespan.unavailable::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 1px;
  left: -0.5px;
  background-color: #919191;
}

.sk-active-timespan .sk-timespan-preview {
  z-index: 101;
  position: absolute;
  top: 0;
  left: 50%;
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  color: white;
  justify-content: center;
  min-width: 0;
  border-radius: 0;
  opacity: 0;
  padding: 0;
  height: 50px;
  width: 100%;
  overflow: hidden;
  transform: translate3d(-50%, 0, 0);
  transition: padding 400ms, transform 400ms ease-out, height 400ms ease-out,
    min-width 400ms ease-out, border-radius 400ms, opacity 400ms;
}
