@import "../../../../styles/settings.scss";

.top-menu {
  height: $top-menu-height;

  .dropdown .ui.dropdown.button {
    background-color: $color-green-01;
    &:hover {
      background-color: $color-green-00;
    }
  }

  &__logo-space-menu-item {
    width: 14em;

    &__admin-panel {
      display: inline-flex;
      align-items: center;
      font-family: "Roboto Light", serif;
      font-size: 24px;
      margin: 0 auto;

      & img {
        margin-right: 8px;
      }
    }
  }

  &__no-border::before {
    display: none;
  }

  &__header-right {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    &__logged-as {
      font-weight: bold;

      &__user {
        padding-left: 5px;
        color: $teal-color;
      }
    }

    &__item {
      &::before {
        display: none;
      }
    }

    &--mobile {
      margin-right: 1em;

      // make sub dropdowns show below button instead of left because of directions="left"
      .ui.dropdown .menu .left.menu,
      .ui.dropdown > .left.menu .menu {
        left: 0;
        right: 0;
        margin: 37px -0.5em 0 0 !important;
      }

      // fix margins for arrow icon because direction="left"
      .ui.dropdown .item .left.dropdown.icon,
      .ui.dropdown .left.menu .item .dropdown.icon {
        margin: -0.78571429em;
      }

      // make arrow icon point down because direction="left"
      .ui.dropdown .left.menu .item .dropdown.icon:before {
        content: "\f0d7";
      }
    }
  }
}

.ui.menu.top-menu--simulation {
  background-color: #fff5ef;
}

.simulation-header {
  display: flex;
  margin: auto;
  align-items: baseline;

  &__title {
    font-weight: 700;
    font-size: large;
    color: $teal-color;
  }
}
