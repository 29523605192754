@import "../../../styles/breakpoints";
@import "../../../styles/colors";

.modal-form {
  height: 100%;
  width: 100%;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  z-index: 1000;

  &__body {
    background-color: var(--bg-main);
    color: var(--modal-form-color);
    padding: 16px;
    position: absolute;
    max-height: 95%;
    overflow: auto;

    @include below-phablet {
      & {
        overflow: auto;
        border-radius: 5px 5px 0 0;
        bottom: 0;
        width: 100%;
      }
    }

    @include above-phablet {
      & {
        border-radius: 5px;
        width: 800px;
        max-width: 90%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}

.ui.button.modal-form__button {
  color: white;
  background-color: var(--modal-form-color);
}
