.error{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin:0 !important;
  
   &__message{
    display:inline-block;
    width:450px;
    text-overflow:ellipsis;
    overflow:hidden;
    white-space:nowrap;
    transition: all .2s linear;
   }
   
   &__date{
   display: flex;
   gap: 10px;
   padding:0 !important;
   align-items: center;
  }
}

