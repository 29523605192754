@import "../../../styles/colors";

.custom-select {
  position: relative;
  display: flex;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  flex-wrap: wrap;
  cursor: pointer;
  min-height: 2.869243em;
  align-items: center;
  background-color: var(--bg-dropdown);
  color: var(--syntax-main);
}

.select-values {
  margin: 4px;
  padding: 4px;
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-radius: 4px;
  background-color: var(--bg-dropdown-selected-item);
  user-select: none;
  cursor: auto;

  .ui.button.delete-value-button {
    padding: 4px;
    margin-left: 4px;
    background-color: var(--bg-dropdown-selected-item);
    width: 20px;

    &:hover {
      background-color: var(--bg-dropdown-hover);
    }
  }

  .delete-value-icon.x.icon:before {
    vertical-align: middle;
    color: var(--modal-form-color);
  }
}

.custom-select-options {
  border: 1px solid rgba(34, 36, 38, 0.15);
  border-top: none;
  z-index: 1001;
  position: relative;
  left: 0;
  right: 0;
  background: var(--bg-dropdown);
  max-height: 200px;

  > div {
    background-color: var(--bg-dropdown);
    padding: 8px;
    color: var(--syntax-main);
    user-select: none;
    cursor: pointer;

    &:not(:last-child) {
      border-bottom: 1px solid rgba(34, 36, 38, 0.15);
    }

    &:hover {
      color: white;
      background-color: var(--modal-form-color);
    }
  }
}

.open-options {
  display: none;
}
