.product-config {
  border: 1px solid white !important;
  gap: 32px;
  display: flex;
  flex-direction: column;

  &__item {
    display: flex;
    align-items: center;

    & > * {
      flex: 1 1 50%;
    }

    &__title {
      color: #00b5ad !important;
      display: block;
      font-size: 16px;
      font-weight: 700;
    }
  }
}
