.template-modal-form {
  .ui.button {
    margin-top: 16px;
  }

  .tagsAndPropsRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
}