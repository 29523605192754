.portrait-container {
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
  
.portrait-container .ui.dimmer {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
}