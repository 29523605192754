@import "../../styles/colors";

.calendar-filters {
  display: flex;
  flex-direction: column;

  &--row {
    flex-direction: row;
    align-items: center;
  }

  &__single-filters {
    display: flex;
    flex-direction: column;
    margin: 12px 0;

    .ui.checkbox label {
      color: $theme-mode-blue;
    }

    &__filter {
      margin-bottom: 6px;
    }
  }

  &__custom-range-title {
    margin-bottom: 18px;
    font-weight: bold;

    &--row {
      margin-bottom: 0;
      font-weight: normal;
      margin-right: 6px;
    }
  }

  &__custom-range-display {
    margin-top: 18px;
    font-weight: bold;

    &--row {
      margin-top: 0;
      font-weight: normal;
      margin-left: 6px;
    }
  }
}

span {
  .react-date-picker__calendar {
    z-index: 102;
  }
}

.calendar-filters__single-filters .ui.checkbox.checked label,
.react-date-picker__wrapper * {
  color: $color-white;
}

.react-date-picker__calendar .react-calendar {
  * {
    color: white;
  }
  &__navigation,
  &__viewContainer,
  button[disabled] {
    background-color: var(--bg-react-calendar);
    margin: 0;
  }

  .react-calendar {
    &__navigation {
      button:hover {
        background-color: $react-calendar-navigation;
      }
    }
    &__tile {
      &--now {
        background-color: $react-calendar-today;
      }

      &--range,
      &--hover {
        background-color: $react-calendar-range-hover;
      }
    }
  }
}
