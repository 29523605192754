@import "../../../../../../../styles/settings.scss";

.billing-report {
  display: flex;
  flex-direction: column;

  * {
    color: var(--syntax-main);
  }

  &__config {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
    margin-bottom: 48px;

    &__input {
      margin: 24px 24px 12px 0;

      & .billing-report-dropdown {
        div {
          background-color: var(--bg-frame);
        }
        padding: 8px 16px;
        border: 1px solid #afafaf;
      }

      &__item {
        text-transform: capitalize;

        &--selected {
          color: $teal-color;
        }
      }
    }
    &__input:last-child {
      margin: 24px 0 12px 0;
    }
  }

  &__results {
    display: grid;

    &__view-select {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      margin-bottom: 48px;

      &__date-display {
        margin-top: 48px;
        font-weight: bold;
      }

      &__percentage-fields {
        display: flex;
        font-weight: bold;
        font-size: 20px;
        margin-top: 48px;
        text-align: center;

        & > span:first-child {
          margin-right: 36px;
        }
      }
    }

    &__table-view {
      overflow-x: auto;

      &__table {
        margin-top: 48px;

        &__popup-details {
          display: flex;
          flex-direction: column;

          &__title {
            display: flex;
            flex-direction: column;
            font-weight: bold;
            margin-bottom: 12px;
          }

          &__invoice-data {
            display: flex;
            flex-direction: column;

            &__invoice {
              display: flex;
              justify-content: space-between;

              &__title {
                margin-right: 12px;
                font-weight: bold;
              }
            }
          }
        }
      }
    }
  }
}
