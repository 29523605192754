@import "../../../../../../../styles/colors";

.users-header {
  display: flex;
  justify-content: space-between;
  padding: 16px;
  border-color: var(--border-theme);
  border-style: solid;
  border-width: 1px 1px 0 1px;

  .users-filters {
    display: flex;
    justify-content: left;
    flex-flow: row wrap;
    gap: 10px;
  }
}

.ui.table.user-table {
  border-radius: 0;

  .availability-table-cell {
    overflow: unset;
  }
}
