
@import "../../../../../../../styles/settings.scss";

.statistic-buttons {
	display: flex;
	flex-direction: column;
	gap: 10px;
}
.bot-config-segment {
	width: 100%;
	text-align: center;
}
.select-product-dropdown {
	margin-bottom: 10px;
}
.bot-edit-modal {
	width: 450px !important;
}
.email-input-wrapper {
	display: flex;
	align-items: center;
	gap: 5px;
}

.ui.green.button.enable-alarm-button{
	margin-bottom: 20px;
}

.bot-alarms-label {
	padding: 0px 10px;
	font-size: 15px;
}

.bot-alarms-select {
	width: 200px;
}